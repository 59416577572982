/* TYPOGRAPHY */


// font families
$font-primary: "google_sansregular";
$font-light:'google_sansregular';
$font-samibold:'google_sansmedium';
$font-bold:'google_sansbold';

$font-secondary-regular: 'hero_newregular';
$font-secondary-semibold: 'hero_newsemibold';
$font-secondary-bold: 'hero_newbold';
$font-secondary-light: 'hero_newlight';
$font-secondary-medium: 'hero_newmedium';

.font-secondary-regular{font-family: $font-secondary-regular;}
.font-secondary-semibold{font-family: $font-secondary-semibold;}
.font-secondary-bold{font-family: $font-secondary-bold;}
.font-secondary-light{font-family: $font-secondary-light;}
.font-secondary-medium{font-family: $font-secondary-medium;}

.font-primary-regular{font-family: $font-primary;}
.font-primary-semibold{font-family: $font-samibold;}
.font-primary-bold{font-family: $font-bold;}
.font-primary-light{font-family: $font-light;color: #8f8c8c;}


//$font-family-base: $font-family-sans-serif;
$font-size-base: 14px; // Assumes the browser default, typically `14px`
$font-size-lg: 1.25rem;
$font-size-sm: 0.875rem;
$font-size-xs: 0.75rem;
$font-weight-normal: normal;
$font-weight-bold: bold;
$font-weight-base: font-weight-normal;
$line-height-base: 1.5;
$font-size-h1: 2.5rem;
$font-size-h2: 2rem;
$font-size-h3: 1.75rem;
$font-size-h4: 1.5rem;
$font-size-h5: 1.25rem;
$font-size-h6: 1rem;

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-bold;
  font-weight: normal; line-height: 130%;
 
}


