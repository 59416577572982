.app-popup {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  background: rgba(0, 0, 0, 0.6);
  left: 0;
  z-index: 9999;
  animation: fadeAnm 0.5s forwards;

  .app-popup-container {
    display: flex;
    height: 100%;
    width: 100%;
    overflow: auto;
    align-items: center;
    justify-content: center;
  }

  .app-popup-content {
    border-radius: 2px;
    background: #fff;
    margin: 20px;
    border-radius: 10px;
    width: calc(100% - 100px);
  }
  .app-popup-body {
    padding: 25px 30px;
  }
  .app-popup-body-scroll {
    max-height: calc(100vh - 200px);
    overflow: auto;
  }
  .app-popup-header {
    padding: 20px 30px;
    position: relative;
  }
  .app-popup-footer {
    padding: 15px 30px;
  }
  .BranchList {
    max-height: calc(100vh - 200px);
    padding-top: 5px;
    overflow: auto;
    li {
      margin-bottom: 30px;
      a {
        color: $color-default;
      }
    }
  }

  .app-popup-810px {
    max-width: 810px;
  }

  .app-popup-700px {
    max-width: 700px;
  }

  .app-popup-600px {
    max-width: 600px;
  }

  .app-popup-980px {
    max-width: 980px;
  }

  .app-popup-485px {
    max-width: 485px;
  }
  .app-popup-600px {
    max-width: 600px;
  }

  .app-popup-465px {
    max-width: 465px;
  }

  .app-popup-1220px {
    max-width: 1220px;
  }

  .app-popup-585px {
    max-width: 585px;
  }
  .app-popup-685px {
    max-width: 685px;
  }
  .app-popup-430px {
    max-width: 430px;
  }

  .app-popup-close {
    width: 41px;
    height: 41px;
    border: none;
    background: #ffffff;
    border-radius: 50%;
    width: 41px;
    height: 41px;
    border: none;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    right: -50px;
    top: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
  }
  &.app-popup-hide {
    display: none;
  }

  &.app-popup-offcanvas-end {
    .app-popup-content {
      border-radius: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
    }
    .app-popup-body-scroll {
      max-height: calc(100vh - 160px);
      flex: 1;
    }
    .app-popup-container {
      justify-content: end;
      align-items: initial;
    }
    .app-popup-close {
      left: -60px;
    }
    .app-popup-header {
      height: 70px;
    }
    .app-popup-footer {
      height: 90px;
    }
    &.app-popup-header-h-105 {
      .app-popup-header {
        height: 100px;
      }
      .app-popup-body-scroll {
        max-height: calc(100vh - 100px);
      }
    }
  }

  &.app-popup-footer-no {
    .app-popup-body-scroll {
      max-height: calc(100vh - 70px);
    }
  }
}

.popup_opened {
  overflow: hidden;
}

.app-popup-padding-2 {
  .app-popup-body {
    padding: 20px 20px;
  }
  .app-popup-header {
    padding: 20px 20px;
  }
}

.filterpopss div:last-child {
  border-bottom: none!important;
}
.filterpopss .form-check .form-check-input[type=checkbox] {  
  margin-right: 7px!important;
}



@include media($xl) {
  .app-popup .app-popup-content {
    width: calc(100% - 150px);
  }
  .app-popup .BranchList li {
    margin-bottom: 20px;
  }
  .app-popup .app-popup-header {
    padding: 20px 30px;
  }

  // .app-popup.app-popup-offcanvas-end {
  //   .app-popup-header {
  //     height: 60px;
  //   }
  // }
  .app-popup.app-popup-offcanvas-end {
    &.app-popup-header-h-105 {
      .app-popup-header {
        height: 70px;
      }
      .app-popup-body-scroll {
        max-height: calc(100vh - 70px);
      }
    }
  }
}
@include media($mobile-landscape) {
  .app-popup .app-popup-content {
    width: calc(100% - 40px);
  }

  .app-popup .app-popup-close {
    left: 0px;
    right: 0;
    margin: -58px auto 0;
    width: 30px;
    height: 30px;

    svg {
      width: 10px;
      height: auto;
    }
  }
  .app-popup .app-popup-header {
    padding: 15px 20px;
  }
  .app-popup .app-popup-body {
    padding: 15px 20px;
  }
  .app-popup .app-popup-footer {
    padding: 15px 10px;
  }
  .app-popup .BranchList {
    max-height: calc(100vh - 220px);
  }
  .app-popup .app-popup-container {
    padding-top: 50px;

    .btn {
      font-size: 16px;
    }
  }

  .app-popup.app-popup-offcanvas-end .app-popup-content {
    width: 100%;
  }
  .app-popup.app-popup-offcanvas-end .app-popup-container {
    padding-top: 0;
  }
  .app-popup.app-popup-offcanvas-end .app-popup-header {
    height: 52px;
  }
  .app-popup.app-popup-offcanvas-end .app-popup-close {
    left: inherit;
    right: 10px;
    top: 26px;
    z-index: 2;
    margin: 0;
  }
  .app-popup.app-popup-offcanvas-end .app-popup-body-scroll {
    max-height: calc(100vh - 130px);
  }
  .app-popup.app-popup-footer-no .app-popup-body-scroll {
    max-height: calc(100vh - 60px);
  }
  .app-popup.app-popup-offcanvas-end.app-popup-header-1 .app-popup-body-scroll {
    max-height: calc(100vh - 70px);
  }
  .app-popup.app-popup-offcanvas-end .app-popup-footer {
    height: 80px;
  }

  .app-popup.app-popup-offcanvas-end {
    &.app-popup-header-h-105 {
      .app-popup-header {
        height: 90px;
      }
      .app-popup-body-scroll {
        max-height: calc(100vh - 90px);
      }
    }
  }

  #Terms_Conditions.app-popup.app-popup-offcanvas-end {
    &.app-popup-header-h-105 {
      .app-popup-header {
        height: 70px;
      }
      .app-popup-body-scroll {
        max-height: calc(100vh - 70px);
      }
    }
  }

  .app-popup-container .filterpopss {
    max-height: calc(100vh - 270px)!important; 
  }

}

@media only screen and (max-width: 375px) {

.app-popup.app-popup-offcanvas-end .app-popup-close {   
  top: 29px;
}


}
