.svg-icon-wrap {
  width: 0px;
  height: 0px;
  overflow: hidden;
}
.page-wrapper {
  padding-top: 100px;
  position: relative;
  padding-bottom: 120px;
  min-height: calc(100vh);
}
.dayName {
  width: 60px;
}
.Select-Time-row {
  margin-left: 0;
  margin-right: 0;
  .btn.btn-outline-primary {
    border-color: #d4d4d4 !important;
    color: #393939;
    min-width: 65px;
    padding: 0 !important;
    justify-content: center;
    width: 100%;
    &:hover {
      border-color: #d4d4d4 !important;
      color: #393939;
      background-color: transparent;
    }
  }

  .btn-check:active + .btn-outline-primary,
  .btn-check:checked + .btn-outline-primary,
  .btn-outline-primary.active,
  .btn-outline-primary.dropdown-toggle.show,
  .btn-outline-primary:active {
    color: #fff;
    background-color: #ee1e3d;
    border-color: #ee1e3d !important;
    color: #fff !important;
  }

  > div {
    flex: 1;
    padding-left: 5px;
    padding-right: 5px;
    width: 25%;
    max-width: 25%;
  }
}

@keyframes fadeAnm {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.BookTable {
  max-width: 410px;
  background: #fff;
}
.my-sticky-element {
  transition: all 0.5s ease;
}
.my-sticky-element.stuck {
  position: fixed;
  top: 75px;
  box-shadow: 0px 3px 15px #0000001a !important;
  border: 1px solid rgba(0, 0, 0, 0.125);
  left: calc(50vw - ((100vw - 100%) / 2 - 265px));
  right: 15px;
  //   height: calc(100vh - 175px);
  //   overflow: auto;
  border-radius: 4px;
  padding: 0px;
  max-width: 380px;

  .card {
    border: none !important;
  }
}

.BookTable-card {
  padding-top: 67px;
}
.BookTable-header {
  height: 64px;
  position: absolute;
  width: 100%;
  background: #fff;
  z-index: 2;
  top: 0;
}
.Select-Time-scroll {
  max-height: 110px;
  overflow: auto;
}
.my-sticky-element.stuck .BookTable-card-body {
  height: calc(100vh - 250px);
  overflow: auto;
}

.rsc-container {
  min-width: 100%;
  .rsc-slider-item-img {
    padding: 0;
  }

  .rsc-navigation {
    width: 57px;
    position: absolute;
    top: calc(50% - 28px);
    height: 57px;
    background-color: rgba(255, 255, 255, 0.8);
    opacity: 0.8;
    border-radius: 50%;
    z-index: 2;
    font-size: 0px;
    border: none;
    background-size: 14px;
    transition: all 0.5s ease;

    &.rsc-navigation_left {
      left: 20px;
    }
    &.rsc-navigation_right {
      right: 20px;
    }
  }
}
.downArrow {
  width: 10px;
  height: 10px;
  border: 2px solid $color-secondary;
  display: inline-block;
  transform: rotate(45deg);
  border-width: 0px 2px 2px 0px;
  position: relative;
  top: -3px;
  margin-left: 3px;
}
.icon_col_circle {
  img {
    max-width: 18px;
    max-height: 18px;
    width: auto;
    height: auto;
  }
}
.amenities_icon {
  padding-top: 4px;
  img {
    max-width: 24px;
    max-height: 18px;
    width: auto;
    height: auto;
  }
}
.curruntDayDD {
  font-family: $font-bold;
}
.contact-wrap-col1 {
  padding-right: 95px;
  margin-right: 95px;
}
.map-wrap {
  width: 520px;
  height: 258px;
}
.element_location {
  display: flex;
  font-size: 16px;
  line-height: 160%;
  svg {
    min-width: 14px;
    top: 4px;
  }
}
.OnlineOrder_span {
  padding-top: 10px;
}
.font-18px-important {
  font-size: 18px !important;
}

.FacilitiesHead_content_wrap {
  width: 100%;
  max-width: 725px;
}
.footer_Links1 {
  a {
    color: #000000B3;
    &:hover {
      color: $color-primary;
    }
  }
}

.footer-inner{
  margin-top: -50px;
}

.footer-social-bg{
  background: #fff;
  padding: 0px 10px;
}

.color-text{
  color: #000000B3;
}

.footer_SocialLinks1 a {
  transition: all 0.5s ease;

  svg {
    max-width: 38px;
  }

  g {
    stroke: $color-secondary;
    transition: all 0.5s ease;
  }
  path {
    fill: $color-secondary;
    transition: all 0.5s ease;
  }

  &:hover {
    g {
      stroke: $color-primary;
    }
    path {
      fill: $color-primary;
    }
  }
}
.restaurants-card-body {
  padding-bottom: 43px;
}
.openCloseStatus {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.text-red {
  color: #ff0101;
}
.hide-element {
  display: none;
}

.TopBanner_row {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.TopBannerSection {
  height: 400px;
  background: transparent linear-gradient(258deg, #ef626c 0%, #3366cc 100%) 0%
    0% no-repeat padding-box;
}
.topbar-n1 {
  border-radius: 4px;
  max-width: 740px;
}

.business_categorie_row {
  .business_categorie_icon {
    width: 50px;
    height: 50px;
    background-color: #f8f8f8;

    img {
      max-width: 24px;
      max-height: 24px;
      width: auto;
      height: auto;
    }
  }
  .business_categorie_col {
    padding: 16px 20px 14px;
    border-radius: 5px;
    .business_categorie_name {
      color: $color-default;
    }
    &:hover,
    &.active {
      background-color: $color-primary;
      .business_categorie_name {
        color: #fff;
      }
    }
  }
}
.HotDeals_section {
  background-color: #f9f9f9;
}
.page-wrapper-footer1 {
  padding-bottom: 120px;
}
.link-primary {
  svg {
    fill: $color-primary;
  }

  &:hover {
    svg {
      fill: $color-primary-dark;
    }
  }
}
.deals_proImg {
  height: 205px;
  .img-outer {
    border-radius: 0.25rem 0.25rem 0 0;
  }
}
.BusinessCat_name {
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.9);
  text-transform: uppercase;
  font-size: 10px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 6px 7px;
}
.BusinessCat_logo {
  width: 67px;
  height: 67px;
  position: absolute;
  top: 20px;
  left: 20px;
  background: #fff;
  z-index: 2;
  border-radius: 5px;

  img {
    max-width: 62px;
    max-height: 62px;
    width: auto;
    height: auto;
  }
}
.HotDeals_off {
  background: url(../../img/ribbon.svg);
  width: 95px;
  height: 31px;
  background-size: cover;
  display: flex;
  position: absolute;
  left: -5px;
  bottom: 20px;
  align-items: center;
  padding-bottom: 4px;
  text-align: center;
  justify-content: center;
  padding-right: 4px;
}
.text-green {
  color: #26a541;
}
.distance_KM {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 3px;
  font-size: 10px;
  margin-left: 10px;
  padding: 6px 10px;
}
.overlay_detail {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.Online_Order_hilight {
  position: absolute;
  top: 15px;
  left: -4px;
  border-radius: 0 3px 3px 0;
  background: $color-secondary;
  color: #fff;
  text-align: center;
  padding: 10px 13px 9px;
  z-index: 2;
  font-size: 10px;
}
.ribbon_corner {
  position: absolute;
  bottom: -6px;
  left: 0;
}
.fill-white {
  fill: #fff;
}
.area-filter.area-filter-n1 {
  min-width: 200px;
  max-width: 200px;
}
.Footer_border .site-footer {
  // border-top: 1px solid #efefef;
}
.brand_img {
  width: 42px;min-width: 42px;
  height: 38px;
}
.paid_status3,
.Unpaid_status3 {
  height: 17px;
  /* UI Properties */
  background: #33af3b 0% 0% no-repeat padding-box;
  border-radius: 2px;
  display: inline-flex;
  align-items: center;
  padding-top: 1px;
}
.Unpaid_status3 {
  background: #ff0000;
}
.ScaneCode_box {
  width: 173px;
  height: 173px;
  img {
    max-width: 100%;
    height: auto;
  }
  svg{
    width: 100%; height: auto;
  }
}

.FiltersButton-wrap {
  .FiltersButton {
    height: 47px;
    padding: 0 25px !important;
  }
  .categorieName_wrap {
    padding-right: 0;
  }
  .badge {
    position: absolute;
    right: -8px;
    top: -6px;
  }
}

.badge_Filter {
  /* height: 40px; */
  background: #efefef;
  font-family: "hero_newmedium";
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  padding: 12px 10px;
}

.link-default:hover {
  svg {
    fill: $color-primary;
  }
}
.AppliedFilter_close {
  &:hover {
    svg {
      fill: $color-primary;
    }
  }
}

.page-wrapper.selected {
  .ListingHeader #app-header .topbar-n1 {
    visibility: visible;
  }
}

.hide_element {
  display: none;
}

.Dining_Span {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  font-size: 10px;
  padding: 6px 10px;
}

.dropdown_app_menu {
  padding-bottom: 10px;
  padding-top: 10px;
  position: relative;
  .dropdown_app {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 5px;
    padding: 7px 15px;
    position: absolute;
    min-width: 175px;
    display: none;
    left: 0;
    background-color: #fff;
    top: calc(100%);
    z-index: 333;
    font-size: 14px;

    ul {
      text-align: left;
      position: relative;
      z-index: 2;

      li {
        padding-left: 0;
        padding-right: 0;
        position: relative;

        &::before {
          content: "";
          width: 3px;
          height: 100%;
          position: absolute;
          left: -15px;
          transition: all 0.3s ease;
          top: 0;
          opacity: 0;
          background: $color-primary;
        }

        a {
          color: $color-default!important;
          line-height: 18px;
        }

        &:hover {
          a {
            color: $color-primary;
          }
          &::before {
            opacity: 1;
          }
        }
      }
    }
    .dropdown_app_arrow {
      position: absolute;
      left: 14px;
      top: -11px;
      z-index: 1;
      height: 11px;
      overflow: hidden;
    }
  }


  // &:hover {
  //   .dropdown_app {
  //     display: block;
  //   }
  // }
}


.word_limit{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  width: 100%;
}


.business_categorie_item:hover {
  .business_categorie_col {
    background-color: #ef626c;
  }
  .business_categorie_name {
    color: #fff;
  }
}

.detail_popUpBody {
  p {
    margin-bottom: 15px;
  }
}
.deal_svg_span {
  margin-right: 7px;
}

#DealDetails {
  .app-popup-header {
    height: 58px;
  }
  .app-popup-body-scroll {
    max-height: calc(100vh - 58px);
  }
}

.SliderRow_1 {
  margin-left: -15px;
  margin-right: -15px;

  .rsc-slider-item {
    position: relative;
  }

  .col-slider {
    width: 100%;
    .card {
      position: static;
    }
    .openCloseStatus {
      right: 0px;
      left: 0px;
      margin: 0 auto;
      width: calc(100% - 30px);
    }
  }
  .rsc-container {
    width: 100%;
    overflow: hidden;
  }
  .rsc-slider-item-img {
    height: auto;
    padding: 0 15px;
  }

  .rsc-container .rsc-navigation {
    top: 125px;
    box-shadow: 0px 3px 6px #00000029;
  }
}

.business_categorie {
  height: auto;
  display: flex;
  align-items: center;
}

.dropdown_toggle_btn {
  position: absolute;
  left: 0;
  top: 0;
  background: red;
  width: 100%;
  height: 100%;
  opacity: 0;
  display: none;
  z-index: 1;
}

.card-header-h-50px {
  height: 50px;
}
.ribbon_blue {
  height: 26px;
  color: #fff;
  padding: 0 10px;
  border-radius: 0 5px 5px 0;
  background: $color-secondary;
  margin-left: -6px;

  .ribbon_corner1 {
    bottom: -3px;
  }
}

.ribbon_Running {
  padding: 0 10px 0 16px;
  height: 26px;
  border-radius: 0 5px 5px 0;
  color: #fff;
  background: $color-green;
  margin-left: -6px;
}
.ribbon_Upcoming {
  background: #dfdfdf;
  color: $color-default;
}
.order_roww .card-footer {
  height: 48px;
}

.app-popup.app-popup-footer-h-60px.app-popup-offcanvas-end
  .app-popup-body-scroll {
  max-height: calc(100vh - 100px);
}
.business_categorie_row {
  > div {
    flex: 0 0 auto;
  }
}
.dropdown_app_menu:hover .dropdown_app {
  display: block;
}
.SliderRow_1 .Online_Order_hilight {
  left: 10px;
}

.product-iframe {
  width: 100%;
  padding: 0px 0px 50px;
  display: flex;
}

.cusines-links {
  color: #000;
  padding: 13px 18px;
  float: left;
  border: solid 1px #E9E9E9;
  border-radius: 25px;
  text-transform: uppercase;
  font-family: "google_sansmedium";
  font-size: 13px;
  margin: 0px 10px 15px 0px;

  &:hover{
    color: #343434;
    background: #f1f1f1;
  }
}

#Voucher_Details{  
  input{
    &:focus{
      box-shadow: inherit!important;
    }      
  }
}


/*Membership Start*/
.list-style-bullet {
  li {
    padding-bottom: 5px;
    position: relative;
    padding-left: 20px;

    &::before {
      content: ".";
      font-size: 35px;
      position: absolute;
      left: 0;
      line-height: 10px;
      top: 0;
    }
  }
}

.pager {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.pager-entries {
  margin-right: 30px;
  display: inline-flex;
  align-items: center;
  color: gray;
}
.pager-entries .dForm-control {
  margin: 0 10px;
}
.pager-entries span {
  font-size: 13px;
}
.pager-entries select {
  width: 65px;
  height: 38px;
  padding-right: 26px;
}
.pagination li {
  display: inline-flex;
  align-items: center;
  color: #333;
}
.pagination li:not(:last-child) {
  margin-right: 1px;
}
.pagination li.active a,
.pagination li:hover a {
  color: #e5002d;
  border-color: #e5002d;
}
.page__btn.prev:hover a,
.page__btn.next:hover a {
  border-color: transparent !important;
}
.pagination a {
  width: 24px;
  height: 24px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  border: 1px solid transparent;
  transition-property: color, border;
  transition: 0.3s;
}
.h-34px {
  height: 34px;
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-y-hidden {
  overflow-y: hidden !important;
}
.Offers_pro_img {
  height: 280px;
}

.data_scroll_table1 {
  min-width: 600px;
}

.tabs-wrapper-membership {
  .tabs-right {
    width: calc(100% - 220px);
  }
}

.SiteNav_order .navbar-nav {
  flex-wrap: nowrap;
  overflow: auto;
}
.SiteNav_order .navbar-nav li {
  flex: 0 0 auto;
}
.allureLogo {
  img {
    max-width: 36px;
    max-height: 36px;
    width: auto;
    height: auto;
  }
}
.onealogo {
  img {
    max-width: 51px;
    max-height: 46px;
    width: auto;
    height: auto;
  }
}
.ArtcaffeLogo {
  img {
    max-width: 80px;
    max-height: 36px;
    width: auto;
    height: auto;
  }
}
.card-header-Cards {
  height: 70px;
}
a {
  cursor: pointer;
}

.btn-bottom-card-body {
  position: relative;
  padding-bottom: 50px;
}
.btn-bottom {
  position: absolute;
  bottom: 15px;
}

.Vouchers_list > div:last-child {
  border-bottom: none !important;
}

.profile_pic {
  width: 68px;
  height: 68px;
}
.edit_profile_pic {
  bottom: 0;
  right: -5px;

  input {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
.black_devider {
  width: 46px;
  height: 5px;
  display: inline-block;
  border-radius: 10px;
  font-size: 0px;
  line-height: 100%;
  background: rgba(0, 0, 0, 0.7019607843);
}
.form-select-arrow2 {
  padding-right: 20px;
  background-position: right center;
}

.dropdown-menu-icon {
  width: 30px;
}
.dropdown_Btn.active ~ .dropdown-menu {
  display: block;
}

.Toastify{
  position: relative;
  z-index: 99999999999!important;
}

.paging-section {
  float: right;
}

.btn-bottom-pad{
  margin-bottom: 70px!important;
}

.member-images{
  img{
    object-fit: cover!important;
    object-position: right;
  }
}

.paging-section span {
  color: #000;
  width: 30px;
  height: 30px;
  border: solid 1px #d9d9d9;
  display: inline-flex;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  border-radius: 50%; 
}

.activepage {
  background: #ef626c;
  border: solid 1px #ef626c!important;
  color: #fff!important;
}

.app-popup-content{
  .form-check{
    label{
      line-height: 20px;
    }
  }
}

/*login-screen update style start*/
.continue-social-btns {
  background: #FFF;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  padding: 0px 15px;
  justify-content: left!important;  
  color: #000000CC;
  position: relative;
  transition: .2s;

  &:hover {
    background: #f5f5f5;
    border: 1px solid #dee2e6;
    color: #000000CC;
  }
  
}

.icon-btns {
  position: absolute;
  right: 15px;
  top: 7px;

  img {
    height: 22px;
  }
}

.divider-row {
  width: 100%;
  border-bottom: solid 1px #DCDCDC;
  height: 11px;

  span {
    background: #fff;
    padding: 2px 11px;
    margin: 0px auto;
    color: #A8A8A8;
    display: table;
  }
}

.login-popups{
  .error{
    margin: 5px 0px 0px;
    font-size: 12px;
  }

  .text-red{
    margin: 5px 0px 0px;
    font-size: 12px;
  }
}

.end-section{
  width: 100%;
}

.popup-scroll-view {
  align-items: start!important;
}

.login-popups{
  .app-popup-body-scroll{
    max-height: inherit!important;
    height: inherit!important;
    overflow: inherit!important;
  }
}

.icon-password {
  position: absolute;
  right: 15px; 
  cursor: pointer; 
  top: 20px;

  svg{
    fill: #999;
  }
}





/*login-screen update style End*/



@media (min-width: 768px) {
  .MobileSearch {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .site-footer-listing .footerLogo img {
    max-width: 100px;
  }
 
}

@include media($xl) {
  .dropdown_app_menu {
    .dropdown_toggle_btn {
      display: block;
      &.active ~ .dropdown_app {
        display: block;
      }
    }    
     
  }

  .rsc-slider-item-img {
    height: 330px;
  }

  .rsc-container .rsc-navigation {
    width: 42px;
    height: 42px;
  }

  .icon_col_circle img {
    max-width: 14px;
    max-height: 14px;
  }

  .contact-wrap-col1 {
    padding-right: 50px;
    margin-right: 50px;
  }

  .page-wrapper {
    padding-bottom: 80px;
    padding-top: 90px;
  }

  .site-footer {
    position: static;
  }

  .page-wrapper-footer1 {
    padding-bottom: 0;
  }

  #app-header {
    .topbar-n1 {
      border-radius: 4px;
      max-width: inherit;
      position: fixed;
      left: 0;
      top: 69px;
      right: 0;
      margin: 0 !important;
      border-radius: 0px !important;
      border-width: 1px 0 1px 0 !important;
    }
  }

  .page-wrapper-headerSearch {
    padding-top: 90px;

    .page-wrapper-headerSearch #app-header {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      height: 70px;
    }
  }

  .SliderRow_1 {
    .img-restaurants {
      height: 210px;
    }
  }
  .SliderRow_1 .rsc-container .rsc-navigation {
    top: 84px;
  }

  .business_categorie_row {
    flex-wrap: wrap;
    justify-content: center;
  }
  .SliderRow_1 .Online_Order_hilight {
    left: 10px;
  }

  .footer-inner {
    margin-top: -25px;
}

.site-footer-listing {
  margin-top: 50px!important;   
}

.pad-tabss{
  padding-left: 15px;
}

}

@include media($lg) {
  .rsc-slider-item-img {
    height: 230px;
  }
  .contact-wrap-col1 {
    padding-right: 0;
    margin-right: 0;
    border: none !important;
  }
  .contact-wrap {
    flex-direction: column;
  }
  .map-wrap {
    width: 100%;
    height: 258px;
  }
  .map-wrap-bottom {
    max-width: 100%;
  }
  .contact-wrap-col2_inner {
    width: 100%;
  }
  .site-footer-content {
    position: relative;    

    .footerLogo {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  }
  .TopBannerSection {
    height: 300px;
  }  
  .pad-tabss{
    padding-left: 15px;
  }


}

@include media($mobile-landscape) {
  .font-40px {
    font-size: 24px;
  }
  .page-wrapper {
    padding-bottom: 70px;
  }
  .page-wrapper-footer1 {
    padding-bottom: 10px;
  }
  .downArrow {
    width: 7px;
    height: 7px;
  }
  .page-wrapper {
    padding-top: 100px;
  }

  .rsc-slider-item-img {
    height: 130px;
  }
  .rsc-container .rsc-navigation {
    width: 32px;
    height: 32px;
  }
  .icon_col_circle img {
    max-width: 10px;
    max-height: 10px;
  }
  .input-group-search1 {
    border-top: 1px solid #ccc;
    margin-top: 0 !important;
  }
  .TopBannerSection {
    height: 240px;
  }
  .business_categorie_row .business_categorie_col {
    padding: 9px 8px;
    border-radius: 5px;
    border: 1px solid #dcd9d9;
    height: 100%;
  }
  .business_categorie_row > div {
    // width: calc(33% - 15px);
    width: calc(100%);
  }

  .business_categorie_row > div:nth-child(3n) .dropdown_app {
    right: 0;
    left: inherit;

    .dropdown_app_arrow {
      left: inherit;
      right: 14px;
    }
  }

  .business_categorie_row .business_categorie_col .business_categorie_name {
    line-height: 18px;
  }
  .app-header-row {
    height: 100%;
    .ListBusiness_CTA {
      position: absolute;
      right: 15px;
      top: 10px;
      padding: 0 18px !important;
    }
  }
  .Header_rt {
    background: #36c;
    margin: 0 -15px;
    height: 42px;
    padding-top: 9px;
  }

  .mobile-header-nav ul li a{
    color: #fff!important;
    font-size: 14px;
    font-family: "google_sansmedium"!important;
  }

  .mobile-header-nav ul li.dropdown_app_menu ul li a{
    color: #2D2E2F!important;    
  }

  .Header_rt.Header_rt_header1 {
    background: transparent;
    margin: 0 0px;
  }
  .headerMenuu li a {
    border-bottom-color: #36c;   
    padding-bottom: 11px;
  }
  .Header_lt {
    flex: 1;
    .logo-app {
      max-height: 50px;
      max-width: 74px;
    }
  }
  #app-header {
    .container {
      height: 100%;
    }
  }
  .area-filter.area-filter-n1 {
    min-width: inherit;
    max-width: inherit;
    border: none !important;
  }
  .FiltersButton-wrap .FiltersButton {
    height: 36px;
    padding: 0 10px !important;
  }

  #app-header .topbar-n1 {
    display: none !important;
  }
  .MobileSearch {
    padding: 20px 15px 10px;
  }
  .page-wrapper-headerSearch #app-header {
    border-bottom-width: 0px !important ;
  }
  .openCloseStatus {
    position: static;
  }
  .restaurants-card-body {
    padding-bottom: 0;
  }
  .business_categorie {
    height: auto;
  }
  .business_categorie_row {
    flex-wrap: wrap;
  }
  .business_categorie_row > div {
    flex: 0 0 auto;
  }
  .SiteNavBar1.SiteNav_order.navbar-header a:not(.hamburger-icon).nav-link {
    font-size: 13px;
    padding: 0 0px !important;
    margin-right: 15px;
  }
  .SiteNavBar1.SiteNav_order li:last-child a:not(.hamburger-icon).nav-link {
    margin-right: 0px;
  }
  .successAlert_icon img {
    max-width: 55px;
  }
  .SliderRow_1 .col-slider .openCloseStatus {
    width: 100%;
  }
  .tabs-wrapper-membership .tabs-right {
    width: 100%;
  }
  .FiltersButton {
    svg{ max-width: 18px;}
  }
  .badge_Filter {
    font-size: 12px;
    padding: 8px 8px;
  }

  .mobile-tops {
      align-items: start!important;
  }

  .form-select{
    font-size: 14px!important;
  }
  .about-top{
    padding-top: 85px!important;
  }
  .pad-tabss{
    padding-left: 0px;
  }

  .line-height-txt{
    line-height: 35px!important;
  }

  .view-mob-text{
    margin-top: 5px;
      span{
        font-size: 14px!important;
      }
      svg{
        width: 5px;
      }
    }

  .mobile-valid-row{
    label {
      width: 100%;
      margin-bottom: 5px;
    }

    span{
      display: none;
    }
  } 

  .popup-scroll-view{
    .app-popup-footer{
      padding: 15px 20px!important;
    }
  } 

}
@include media($sm) {
  // .business_categorie_row {
  //   flex-wrap: wrap;
  //   > div {
  //     width: 50%;
  //     margin-left: 0 !important;
  //     margin-right: 0 !important;
  //   }
  // }

  .TopBanner_heading {
    padding: 0px 20px;
  }

  br{
    display: none!important;
  }
  .silder-top-0{
    margin-top: 15px;
  }

  .mobile-row-catergories {
    width: 100%;
  }

  .mobile-silde-box{
    .business_categorie_item {     
      width: 45%;
    }
  } 

  .Online_Order_hilight {  
    top: 20px;
  }

  .mobile-pb-0{
    padding-bottom: 0px!important;
  }
}

@media only screen and (max-width: 992px) {
.business_categorie_row .business_categorie_col {
  padding: 9px 8px; 
}

.mobile-link {
  width: 100%!important;
  overflow-x: auto;
  display: block!important;
}

.mobile-scroll{
  width: auto;
  display: flex;

  .cusines-links{
    flex: 0 0 auto;
  }
}

.home-link {
  width: 100%;
  overflow-x: auto;
  display: block!important;
}

.mobie-navs{
  width: auto;
  display: flex;

  .cusines-links{
    flex: 0 0 auto;
  }
}

.mobile-row-catergories{
  display: flex;
  justify-content: start;
}

.mobile-silder-view {
  width: 100%;
  overflow-x: auto;
  display: block!important;
}

.mobile-silde-box{
  width: auto;
  display: flex;

  .business_categorie_item{
    flex: 0 0 auto;
  }
}


.cusines-links {
  padding: 8px 10px;
  font-size: 11px;
  margin: 0px 8px 4px 0px;
}

.business-cat-tablet {
  width: calc(100%)!important;
  flex: inherit!important;
}

}


@media only screen and (max-width: 600px) {
.rsc-container .rsc-navigation.rsc-navigation_left {
  top: 36%;
}
.rsc-container .rsc-navigation.rsc-navigation_right {
  top: 36%;
}
.business_categorie_row > div {
  // width: calc(47% - 15px);
  width: calc(100%);
}

.btn-bottom-pad{
  margin-bottom: 50px!important;
}

.footer_Links1 {
  margin: 8px 0px!important;
  line-height: 22px;
}

.mobile-link{
  display: inherit!important;
  float: left;
  margin: 10px 0px 0px!important;
}

.mobi-br br{
  display: none;
}

}

