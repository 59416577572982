.myinvesthead{        
    width: 100%;
    float: left;
    border-bottom: 1px solid #D9D9D9;
    padding: 5px 0px 15px;

    .headinfo{
        padding-left: 10px;
        float: left;
    }
    
    .investtxt{
        float: left;
        border-right: solid 1px #D4D4D4;
        padding-right: 30px;

        p{
            color: #606060;            
        }
    } 
    .touchtxt{          
        margin: 0px 0px 0px 10px;

        label{
            color: #2D2E2F;
            font-size: 16px;            
        }
    }
    
    .orderimgshow{        
        float: left;
    }
}

.backs{
    float: left;
    margin-top: 7px;;
}
.inveimg{
    float: left;
}
.pointshow{
    background: #E9E9E9;
    color: #575757;
    border-radius: 50%;
    width: 27px;
    height: 27px;
    float: left;
    text-align: center;
    padding-top: 6px;    
}


.daylist{
    float:right;
    ul{
        display: block;

        li{
            float: left;
            margin-left: 10px;
        }
    }
    .dayboxs {
        border: solid 1px #E2E2E2;
        padding: 8px 10px;
        border-radius: 4px;
        font-size: 12px;
        color: #686868;
    }
}

.dayselect{
    background: #E5F3FF;
    border: solid 1px #D1DFFC!important;
    color: #3366CC!important;
}

.dropdays {
    border: solid 1px #E2E2E2;
    padding: 6px 10px;
    border-radius: 4px;
    font-size: 12px;
    color: #686868;
    position: relative;
    /*-webkit-appearance: none;*/
    background-size: 14px;
    outline: none;

    & :focus{
        outline: none;
    }
}

.salestablerow{
    width: 100%;
    padding: 30px 0px 20px;
    float: left;
}
.salehead{
    width: 100%;
    float: left;
    
    h2{
        color: #2D2E2F;

        span{
            color: #3366CC;
        }
    }
    h5{
        font-weight: 600;
        color: #2D2E2F;
        font-family: "google_sansmedium";
        font-weight: normal;
    }
}

.activepage{    
    border-bottom: solid 3px #454545;
}

.salfilter{
    float: right;    
}
.dateshow{
    float: left;
}
.dateshow{
    float: left;
    width: 265px;
    border: solid 1px #c1c1c1;
    padding: 4px 10px;
    border-radius: 4px;    

    label{        
        color: #5E5E5E;
        float: left;
        margin-top: 13px; 
        font-size: 14px;;       
    }
    input{
        padding: 10px 0px;
        margin-left: 7px;
        width: 195px;
        font-size: 14px;
        border: none;
        outline: none;
        color: #2D2E2F;
        font-family: "google_sansmedium";        
    }
}

.pointdiv{
    float: left;   
    border: solid 1px #c1c1c1;
    padding: 0px 10px;
    border-radius: 4px; 
    margin-left: 10px; 
    position: relative;
    min-width: 180px;
    
    label{
        color: #5E5E5E;
        font-size: 14px;
        position: absolute;
        left: 10px;
        top: 0px;
        width: 80px;
        background: #fff;
        padding: 17px 0px 10px;    
        }
}
.pointlist{
    float: left;    
    min-width: 60px;
    font-size: 14px;
    padding: 14px 20px 14px 80px;
    border: none;
    font-family: "google_sansmedium";
    outline: none; 
    background: none!important;  

    & :focus{
        outline: none;
    }
    
}
select.pointlist option {   
    padding: 10px;    
    direction:rtl !important;
    text-align:left;
 }

.tablesaleinfo{
    padding: 15px 0px;
    width: 100%;
    float: left;

    td:last-child{
        position: relative;
    }

}

.infoshow {
    display: grid;
    height: 250px;
    text-align: center;
    align-items: center;
    font-size: 20px;
}
.paidtxt {
    float: right;
    clear: both;
    margin: 5px 0px 0px;
}

// .view_order{
//     box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
//     border: 1px solid #D6D6D6;
//     border-radius: 4px;
//     padding: 7px 10px;
//     color: #000000CC;
//     font-size: 13px;
//     display: block;

//     svg{
//         margin-right: 5px;
//         font-size: 13px;
//     }

//     &:hover{
//         color: #000000CC!important;
//     }
// }
.hide_view{
    display: none;
}
  
/*view order popup style start*/

.orderlistinfo{
    width: 100%;
    float: left;

    ul{
        width: 100%;
        float: left;

        li{
            width: 100%;
            display: block;
            margin-bottom: 5px;
        }
    }
    
    h2{
        margin-bottom: 2px;
        color: #EF626C;
    }

    h3{
        margin-bottom: 2px;
        color: #161616;
    }

    h4{        
        color: #4E4E4E;
        float: right;
    }

    h5{
        color: #141414;
        width: 100%;
        float: left;
    }
   
    h6{        
        color: #4E4E4E;
    }
    label{
        color: #666666;
        line-height: 18px;
    }
}
.border_r_none{
    border-right: none!important;
}
.itemimg{
    width: 100%;

    img{
        width: 100%;
        border-radius: 3px;
    }
}
.padspace0{
    padding: 0px;
}

.totalitemrow{
    width: 100%;
    float: left;    
    border-top: solid 2px #B2B2B2;    

    span{
        color: #000000CC;
    }

    label{
        color: #EF626C;
        float: right;
    }
}

.start-date-pikar{
    .react-datepicker-wrapper{
        width: 100%;
    }
}






@media (max-width: 1024px) {

.tablesaleinfo {   
    width: 100%;    
    overflow-x: auto;

    table{
        width: 1500px;;
    }
}
.salfilter {
    float: left;
    margin: 20px 0px 0px;
}

}

@media (max-width: 575px) {
    .myinvesthead .touchtxt {      
        margin: 0px;        
    }
    .myinvesthead .investtxt {
        float: left;
        border-right: none;
        padding-right: 0px;
    }
    .pointdiv {    
        margin: 0px 0px 0px;
    } 

    .daylist {
        float: left;
        margin-top: 15px!important;

        ul{
            li{
                margin-left: 0px!important;
            }
        }
    }  
    
}













