.filterrights{
    float: right;
}
.searchinputfilter {
    padding: 10px 15px;
    font-size: 14px;
    color: #2D2E2F;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    float: left;
    margin: 0px 10px;
    height: 47px;
    width: 260px;
}

.approval_list{
    width: 100%;
    float: left;
    padding: 15px 0px;  
    margin-bottom: -15px;  

    ul{
        width: 100%;
        float: left;

        li{
            float: left;
            margin-right: 30px;

            a{                
                font-size: 18px;                
                padding: 15px 0px;
                font-family: "google_sansmedium";
            }   
        }

        
    }

}

.approval_list li:nth-child(1) a {
    color: #3366CC;
}

.approval_list li:nth-child(2) a {
    color: #18C100!important;
}

.approval_list li:nth-child(3) a {
    color: #FF0000!important;
}

.approval_list li:nth-child(4) a {
    color: #FF9E0D!important;
}

.active1{    
    border-bottom: solid 3px #3366CC;
}

.active2{
    border-bottom: solid 3px #18C100;
}

.active3{
    border-bottom: solid 3px #FF0000;
}

.active4{
    border-bottom: solid 3px #FF9E0D;
}
.pendingColor{
    color : #3366CC
}
.approveColor{
    color : #18C100
}
.rejectColor{
    color : #FF0000
}
.holdColor{
    color : #FF9E0D
}


.labelcolor{
    color: #00000080;
}

.fillcolor{
    color: #000;
}

.bordercolor{
    border-bottom: solid 1px #E5E5E5;
    margin: 10px 10px 5px;    
}

.moreless-button{
    color: #3366CC;
    font-size: 16px;
    text-decoration: underline!important;

    & :hover,
    & :focus{
        color: #3366CC!important;
    }
}

.pdfticketicon{
   margin:0px 10px 0px 0px;
   float: left;
}

.ticketinfo{
    float: left;
}

.previewlink{
    color: #005276;
    text-decoration: underline;
}

.viewpreview{
    float: left;
    margin:0px 10px 0px 20px;
}

.previewlink svg {
    margin: 0px 8px 0px 0px;
}

.userimg{
    height: 30px;
    float: left;
    margin-right: 10px;
}

.usertxt {
    float: left;
    margin: 5px 0px 0px 5px;
}


/**/

.req_footer{
    width: 100%;
    float: left;
    padding: 25px 15px;
    box-shadow: 0px 3px 20px #0000001A;
    border-top: 1px solid #E1E1E1;
    background: #fff;

    label{
        width: 100%;
        float: left;
        text-align: center;
        font-size: 12px;
        color: #000;
        opacity: 0.7;
        padding: 10px 0px 0px;
    }
    
}
.footer_btn{
    width: 100%;
    float: left;
    ul{
        width: 100%;
        // text-align: center;
        float: left;

        li{            
            margin: 2px 5px;
            float: left;
        }
    }
}

.filterwid > div {
    max-width: inherit!important;
}

.approvshowbtn1{
    float: left;
    background: #00CC00;
    text-align: center;
    color: #fff;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    text-decoration: none!important;
}
.approvshowbtn1:hover, .approvshowbtn1:focus {
    float: left;
    background: #009a00;
    color: #fff!important;
}
.approvshowbtn1 svg{
    margin:0px 8px 0px 0px;
    float: left;
}
.rejectbtn2{
    float: left;
    background: #FF0000;
    text-align: center;
    color: #fff;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    text-decoration: none!important;
}
.rejectbtn2:hover, .rejectbtn2:focus {
    float: left;
    background: #E30808;
    color: #fff!important;
}
.rejectbtn2 svg{
    margin:0px 8px 0px 0px;
    float: left;
}
.holdbtns3{
    float: left;
    background: #FF9E0D;
    color: #fff;
    text-align: center;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    text-decoration: none!important;
}
.holdbtns3:hover, .holdbtns3:focus {
    float: left;
    background: #E18A09;
    color: #fff!important;
}
.holdbtns3 svg{
    margin:0px 8px 0px 0px;
    float: left;
}


.border-bottom-divide{
    border-bottom: solid 1px #E5E5E5;
    margin: 5px 0px;
    width: 100%;    
}
.colorhead{
    color: #393939;
}

.pointlist{
    width: 100%;
    float: left;
}

.selectbg{
    position: relative;        
}

.arrowlist{
    background: url(../../img/down-arrows.png) 94% 16px no-repeat;;          
}

.requestcolor{
    color: #00C400;
}

.reuestarea{
    width: 100%;
    float: left;    
    border:none;
    outline: none;
    font-size: 16px;
    color: #000;
    line-height: 25px;
    font-weight: normal;
    height: 60vh;
}

.otpbtn{
    width: 100%;
    float: left;
    background: #EC626D;
    padding: 15px 10px;
    border-radius: 3px;
    color: #fff!important;
    outline: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    text-align: center;
    transition: .2s;
}
.otpbtn:hover{
    background: #E64855;
    color: #fff!important;
}

.app-popup-375px{
    max-width: 375px;
}


.verifydetails{
    width: 100%;
    float: left;

    h2 {
        width: 100%;
        font-size: 16px;
        color: #000000CC;
        font-family: "google_sansregular";
        float: left;
        font-weight: normal;
        line-height: 24px;
    }

    h6 {
        width: 100%;
        font-size: 16px;
        color: #000000CC;      
        float: left;      
        font-weight: normal;          
    }

    label {
        width: 100%;
        font-size: 14px;
        color: #5E5E5E;
        font-family: "google_sansregular";
        float: left;
        font-weight: normal;
    }
}
.submitapprovbtn{
    width: 100%;
    float: left;
    text-align: center;
    color: #fff;
    margin:15px 0px 0px;
    padding: 18px 0px;    
}

.verifyinput {
    width: 100%;
    float: left;
    padding: 14px 15px;
    border: solid 1px #CCCCCC;
    color: #00000099;
    border-radius: 3px;
    margin: 10px 0px;
    font-size: 16px;
    outline: none;
}

@media only screen and (max-width: 992px) {
    .searchinputfilter {
        padding: 10px 10px;
        font-size: 12px;
        margin: 0px 10px;
        height: 47px;
        width: 180px;
    }
}


@media only screen and (max-width: 767px) {
    .filterrights{
        float: left;
        margin: 10px 0px 0px;
        width: 100%;
    }

    .searchinputfilter {
        margin: 0px 15px 0px 0px;
        width: 250px;
        height: 37px;
        padding: 0px 15px;
    }   
    
}

@media only screen and (max-width: 600px) {
    .approval_list {
        overflow-x: auto!important;
        overflow-y: hidden!important;
        margin-top: 0px!important;

        ul {
            width: auto;
            display: flex;
            li {
                padding: 10px 0px;
                flex: 0 0 auto;
                margin-right: 15px!important;

                a{
                    font-size: 14px;
                }
            }    
        }
    }    

    .req-row-mobile {
        width: 100%;
        float: left;
        margin: 5px 0px 5px;
    }

}


@media only screen and (max-width: 375px) {
    .searchinputfilter {
        width: 180px;
        height: 37px;     
    }
    .mobile-btns{
        ul{
            li{
                margin: 2px 3px!important;

                a {
                    padding: 10px 10px!important;
                }
            }
        }
    } 
}