a {
  transition: all 0.6s ease;
}
.footer-links {
  a {
    color: rgba(0, 0, 0, 0.7) !important;
    text-decoration: none;
    padding: 0 8px;
    font-size: 18px;
  }
}
.social-media-links {
  .social-media-row {
    a {
      margin: 0 20px;
      &:hover {
        opacity: 0.5;
      }
    }
  }
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    border-bottom: 1px solid $color-secondary-light;
    margin-top: 5px;
  }
}

.site-footer {
	position: absolute;
	bottom: 0;
	width: 100%;
}

@include media($xl) {
  .social-media-links .social-media-row a {
    margin: 0 15px;

    img {
      max-width: 28px;
      max-height: 28px;
    }
  }
  .social-media-links::after {
    margin-top: 0;
  }
  .navbar-header a:not(.hamburger-icon).nav-link {
    font-size: 18px;
  }

}

@include media($mobile-landscape) {
  .site-footer{font-size: 13px;bottom: 58px;
    .font-22px {
      font-size: 14px;
    }
  }
  .social-media-links .social-media-row a {
    margin: 0 10px;

    img {
      max-width: 18px;
      max-height: 18px;
    }
  }
  .social-media-links::after {
    margin-top: 0;
  }
  .footer-links {
    margin: 0 -15px;
    a {
      color: rgba(0, 0, 0, 0.7) !important;
      text-decoration: none;
      padding: 0 2px;
      font-size: 14px;
    }
  }
  .footerLogo {
    img {
      max-width: 70px;
    }
  }
}
