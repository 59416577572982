.photos-row {
  margin: 0px 0px 0;
  > div {
    padding-left: 0px;
    padding-right: 0px;
    width: 20%;
    flex: auto;
  }
    .insider-box {
    width: 100%;
    height: 250px;
    position: relative;
    overflow: hidden;
    background: #333;
  }
  .insider_caption {
    position: absolute;
    left: 0px;
    top: 30px;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 15px;
    text-align: center;
    transition: all 0.3s ease;
  }
  .insider-box:hover .insider_caption {
    opacity: 1;
    top: 0px;
  }

}

.photos-row_design_2{
  .insider-box_design2_wrap{border: 1px solid rgba(0,0,0,0.1); height: 100%;}
  .insider-box_design2{ height: 250px; background-color: #333;}
  .insider_caption_design2{ padding: 12px;}
  .insider-box_design2_wrap{}
}

.photo-lg-wrap {
  height: 448px;
}
.photos-row-2 {
  margin: -10px 5px 0;
  > div {
    padding-left: 5px;
    padding-right: 5px;
    height: 219px;
    position: relative;
    margin-top: 10px;
  }
  .MorePhotos {
    left: 5px;
    right: 5px;
  }
}

.nav-tab-wrapper {
  .nav-link {
    padding: 15px 22px;
    border: 1px solid #d8d8d8;
    color: $color-default;
    border-radius: 3px;
    font-size: 20px;
    margin-right: 20px;
  }
  .nav-pills .nav-link:hover,
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #fff;
    background-color: $color-primary;
  }
}

.app_popup_wrap {
  position: fixed;
  top: 0px;
  display: block;
  left: 0px;
  z-index: 5;
  height: 100%;
  width: 515px;
  background: #fff;
}
.app_popup_backdrop {
  position: fixed;
  top: 0px;
  display: block;
  left: 0px;
  width: 100%;
  height: 100%; z-index: 4;
  background: rgba(0, 0, 0, 0.8);
}
.insider-user {
	background: linear-gradient(40deg, #f99b4a 15%, #dd3071 50%, #c72e8d 85%);
	width:40px;
	height: 40px;
	border-radius: 50%;
	padding: 4px;
	box-sizing: border-box;
	margin-right: 12px;

  .img-outer{border-radius: 50%;
    border: 3px solid #fff;}
}

.insider-user img {
	display: block;
	border-radius: 50%;
	box-shadow: 0 0 0 2px #fff;
	box-sizing: border-box;
	transition: all 0.2s ease;
	margin: 0 !important;
}
.insder_post_fullImg{ height:400px;}
.hide-popup{ display: none;}
.app-popup-close-Btn svg{ width: 18px;}
.app_popup_body {
	max-height: calc(100vh - 73px);
	overflow: auto;
}

@media (max-width: 1199px) {
  .photos-row_design_2 .insider-box_design2 {
    height: 180px;
  }
}


@media (max-width: 767px) {
  .photos-row_design_2 .insider-box_design2 {
    height: 250px;
  }
  .app_popup_wrap{ width: 100%;}
  .insder_post_fullImg {
    height: 300px;
  }
}