.deliverysection{
    width: 100%;
    padding: 20px 0px;
    float: left;
}

.deliverytable,
.webtable{
    width: 100%;
    float: left;

    th{
        font-size: 14px;
        font-family: "google_sansmedium";
        line-height: 18px;
        
        &:first-child{
            border-radius: 8px 0px 0px 0px!important;
        }

        &:last-child{
            border-radius: 0px 8px 0px 0px!important;
        }
    }
    td{
        font-size: 14px;
    }    
    td:last-child{
        position: relative;
    }

    tr{
        &:last-child{
        td{
            &:first-child{
                border-radius: 0px 0px 0px 8px!important;
            }
    
            &:last-child{
                border-radius: 0px 0px 8px 0px!important;
            }
        }
        }
    }
}
.webtable{
    overflow-x: inherit!important;
}
ul.actionlist{
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
    border: 1px solid #D6D6D6;
    border-radius: 4px!important;
    color: rgba(0, 0, 0, 0.8);
    font-size: 13px;
    margin: 5px 0px;
    position: absolute;
    top: 5px;
    display: inline-table;
    min-width: 130px;
    background: #fff; 

    li{
        padding: 7px 8px;

        a {
            color: #000;
            font-size: 13px;           
        }        
        svg{
            font-size: 13px;
            margin-right: 4px;
        }
    }

    & :hover{
        background: #f5f5f5;
    }
}

.linkstab{
    color: #3366CC;
    text-decoration: underline;
}

.linkstab:hover{
    color: #EF626C!important;
}

.border-bottom-row{
    border-bottom: solid 1px #D2D2D2;
    margin: 10px 10px 5px;
}

.statustxt{
    display: block;

    span{
        color: #2CC400;
    }
}
.dellist{
    width: 100%;
    float: left;

    ul{
        display: block;   
        width: 100%;   

        li{       
            margin: 5px 0px;
            float: left;
        }
    }
}

.delivery-box-border{
    width: 100%;
    float: left;
    border:solid 1px #DCDCDC;
    border-radius: 5px;
}

.deliveryhead{
    width: 100%;
    float: left;
    padding: 20px 10px 40px;
    border-bottom: solid 1px #DCDCDC;
}

.statusdelver{
    width: 100%;
    float: left;
    ul{
        width: 100%;
        float: left;
        text-align: center;
       
    }
    
    li {        
        position: relative;
        padding: 0px 15px;
        display: inline-block; 
        font-size: 14px;
        width: auto;
        font-family: "google_sansmedium";               
    
        &::before {
            content: "";
            position: absolute;
            left: 48%;
            bottom: -24px;
            width: 10px;
            height: 10px;
            background: #2CC400;
            border-radius: 50%;
        }

        &::after {
            content: "";
            position: absolute;
            left: 53%;
            bottom: -20px;
            border-bottom: solid 2px #2cc400;
            z-index: 1;
            width: 108%;
        }
        
      }
    
}

.statusdetails{
    width: 100%;
    float: left;
    padding: 20px 10px 0px;
}

.date_status{
    width: 100%;
    float: left;
    padding: 10px;

    li{
        width: 100%;
        float: left;
        margin-top: -19px;
        margin-bottom: 15px;             
    }
    
}

.del_box_status{
    width: 100%;
    float: left;
    background: #F3F3F3;
    border-radius: 3px;
    padding: 10px 15px;
}

.padr{
    padding-right: 0px;
}

.datebox{
    width: 100%;
    float: left;
    position: relative;
    height: 100%;

    ul{
        float: left;
        position: relative;
        height: 100%;

        li{
            width: 100%;
            float: left;
            position: relative;  
            height: 100%;      
            
            &::before {
                content: "";
                position: absolute;
                right: -15px;                
                background: url(../../img/border-arrow.jpg) left top no-repeat;
                width: 1px;                
                height: 100%;
                z-index: 99999!important;
                background-position: bottom!important;
                bottom: 0px!important;
            }
            &::after {
                content: "";
                position: absolute;
                right: -15px;
                top: 8px;                
                height: 100%;
                border-right:solid 1px #2D2E2F;
                z-index: 1;
            }  
        }
    }

    

}


.datelists{
    width: 100%;
    float: left;    
    position: relative;
    height: 100%;
    
    &::before {
        content: "";
        position: absolute;
        right: -20px;
        top: 3px;
        width: 10px;
        height: 10px;
        background: #2D2E2F;
        border-radius: 50%;
        z-index: 99;
    }  
   
}

.delcover{
    background: #FFF9EF!important;
}


.statusdetails > div:last-child li::after {
    display: none;
}

.statusdetails > div:last-child li::before {
    display: none;
}

.statusdetails > div:last-child li {
    margin-bottom: 0px;
}

.statusdelver li:last-child::after {
    display: none;
}

.codestrip {
    width: 100%;
    float: left;
    border: solid 1px #DCDCDC;
    padding: 40px 20px;
    text-align: center;
    border-radius: 5px;
    margin: 15px 0px;
}

.cut_detailstxt{
    margin-left: 15px;
}
.colorlinks{
    color: #000;
}

.z-index0{
    z-index: 9!important;
}

.deliverytable table tr:nth-child(1),
.deliverytable table tr:nth-child(2){
    .dropdown_Btn.active ~ .dropdown-menu {
        display: block;
        position: absolute!important;
        bottom: 0px!important;
    }
}

.Invoices_table_wrap table tr:nth-child(1),
.Invoices_table_wrap table tr:nth-child(2){
    .dropdown_Btn.active ~ .dropdown-menu {
        display: block;
        position: absolute!important;
        bottom: 0px!important;
    }
}

.webtable table tr:nth-child(1),
.webtable table tr:nth-child(2){
    .dropdown_Btn.active ~ .dropdown-menu {
        display: block;
        position: absolute!important;
        bottom: 0px!important;
    }
}

.Invoices_table_wrap table tr td:first-child{
    border-left: none!important;
}


@media (max-width: 1440px) {
    .statusdelver ul::after {
        left: 15%;
        width: 69%;
    }
}

@media (max-width: 1024px) {
.webtable {
    overflow-x: auto!important;
}
.deliverytable table{
    width: 1000px;
}
ul.actionlist {
        right: 0px;
}

}
@media (max-width: 600px) {

.statusdelver li::after {
    content: "";
    width: 113%;
    }

.statusdelver li {
    padding: 0px 5px;
    font-size: 13px;
}
.datelists span {
    font-size: 11px;
}
.mobilepads{
    padding: 0px 12px!important;
}


}



@media (max-width: 375px) {
.statusdelver li {
    padding: 0px 4px;
    font-size: 11px;
}
.datebox ul li::before {
    bottom: -46%;
}

}