/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 23, 2022 */



@font-face {
    font-family: 'proxima_novasemibold';
    src: url('proximanova-sbold-webfont.woff2') format('woff2'),
         url('proximanova-sbold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'proxima_novabold';
    src: url('proximanova-bold-webfont.woff2') format('woff2'),
         url('proximanova-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'proxima_novalight';
    src: url('proximanova-light-webfont.woff2') format('woff2'),
         url('proximanova-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'proxima_novaregular';
    src: url('proximanova-reg-webfont.woff2') format('woff2'),
         url('proximanova-reg-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}