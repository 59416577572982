.area-filter {
  min-width: 350px;
}
.img-restaurants {
  height: 310px;
}
.filter-categorieName a {
  color: #3e3e3ecc;
  font-size: 16px;
  position: relative;
}
.filter-categorieName a::before {
  content: "";
  position: absolute;
  width: 0px;  transition: all 0.5s ease;
  height: 2px; left: 50%;
  background: $color-default;
  bottom: -5px;
}
.filter-categorieName li {
  margin-right: 20px;
}
.filter-categorieName a:hover,
.filter-categorieName .active a {
  color: $color-default;
}
.filter-categorieName a:hover::before,
.filter-categorieName .active a::before{
    width: 100%; left: 0px;
}
.filter-contentBox > div{ max-width: 520px; }
.Devider-1px{ min-width: 1px;}
.FilterFixBar{ transition: all 0.9s ease;}
.FilterFixBar-fixed {
	position: fixed;
	top: 69px;transition: all 1s ease;
	z-index: 2; border-bottom: 1px solid #eee;
}

#app-header.darkHeader + .RestarListingContainer {
	margin-top: 35px !important;
}


@include media($mobile-landscape) {
#app-header.darkHeader + .RestarListingContainer {
	margin-top: 70px !important;
  .h-52px {
    height: 44px !important;
  }
}
.topbar-1  {
	flex-direction: column;
  .input-group{ margin-top: 10px;}
}
.area-filter {
	min-width: inherit;margin: 0 !important;
}
}

