/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on December 13, 2022 */



@font-face {
    font-family: 'google_sansbold';
    src: url('googlesans-bold.woff2') format('woff2'),
         url('googlesans-bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'google_sansbold_italic';
    src: url('googlesans-bolditalic.woff2') format('woff2'),
         url('googlesans-bolditalic.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'google_sansitalic';
    src: url('googlesans-italic.woff2') format('woff2'),
         url('googlesans-italic.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'google_sansmedium';
    src: url('googlesans-medium.woff2') format('woff2'),
         url('googlesans-medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'google_sansmedium_italic';
    src: url('googlesans-mediumitalic.woff2') format('woff2'),
         url('googlesans-mediumitalic.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'google_sansregular';
    src: url('googlesans-regular.woff2') format('woff2'),
         url('googlesans-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}