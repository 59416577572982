.tabs-left {
  width: 192px;
  min-width: 192px;
  padding: 20px 0;
}
.tabs-left ul li {
  margin-bottom: 2px;
}
.tabs-left a {
  color: #333333;
  padding: 12px 13px;
  border-right: none !important;
  display: block;
  border: 1px solid #fff;
  border-radius: 3px 0px 0px 3px;
  position: relative;
  overflow: hidden;
}
.tabs-left a::before {
  content: "";
  width: 4px;
  height: 100%;
  background: #e5002d;
  border-radius: 2px 0px 0px 2px;
  position: absolute;
  left: 0;
  opacity: 0;
  top: 0;
  transition: all 0.5s ease;
}
.tabs-left ul li:hover a,
.tabs-left ul li.ui-tabs-active a {
  border-color: #e8e8e8;
}
.tabs-left ul li:hover a::before,
.tabs-left ul li.ui-tabs-active a::before {
  opacity: 1;
}

.cart-empty-insts {
  height: 260px;
}
.quantity {
  .quantity__input {
    max-width: 20px;
  }
}
.dish-categorie-name {
  width: 18px;
  min-width: 18px;
}

.customize-proList {
  > div:last-child {
    border-bottom-width: 0px !important;
    padding-bottom: 0px !important;
    margin-bottom: 0 !important;
  }
}
.hide-emptyCart{ display: none;}
.quantity-65px{ min-width: 65px;}
.hide-btn{ display: none;}



