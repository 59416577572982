.logo-2 {
  display: none;
}

@keyframes HeaderLogo {
  0% {
    width: 179px;
  }
  100% {
    width: 110px;
  }
}

.navbar-header {
  li {
  }
  a:not(.hamburger-icon) {
    &:focus {
      color: $color-default;
    }

    color: $color-default;
    border-radius: 4px;
    height: 55px;
    line-height: 40px;
    padding: 0 0px !important;
    margin: 0 auto;

    &.nav-link {
      position: relative;
      font-size: 15px;
      transition: all 0.4s ease;
      padding: 0 15px 0 !important;
      display: inline-block;font-family: "google_sansmedium";
      &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        width: 0px;
        height: 3px;
        margin: 0 auto;
        transition: all 0.4s ease;
        background: $color-secondary;
        bottom: 0px;
      }
    }
  }

  li {
    &:hover,
    &:focus,
    &.active {
      a:not(.hamburger-icon) {
        color: $color-secondary;
        &::after {
          width: 100%;
          color: $color-secondary;
        }
      }
    }

    &.active {
      a:not(.hamburger-icon) {
        font-family: $font-samibold;
      }
    }
  }
}

#app-header { background-color: #fff;
  height: 100px;
  // transition: all 0.7s ease;
}
.darkHeader {
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  padding: 0px !important;

  &.nav-link {
    font-size: 14px;
  }

  a:not(.hamburger-icon).nav-link {
    color: #393939;
    font-size: 16px;
  }
  .btn-outline-light {
    color: $color-primary;
    border-color: $color-primary;

    &:hover,
    &:focus {
      background: $color-primary;
      color: #fff;
    }
  }

  .btn {
    height: 34px;
    line-height: 34px;
  }
}

.banner-site {
  height: 745px;
  background-repeat: no-repeat;
  background-size: cover;
}

.hamburger-icon {
  cursor: pointer;
  z-index: 5;
  display: none;
  width: 22px;
  span {
    transition: all 0.3s ease;
    width: 100%;
    height: 0px;
    margin-bottom: 3px;
    display: block;
    border-top: 3px solid;
    line-height: 13px;
    float: right;

    &:nth-child(1) {
      border-color: #004b93;
    }

    &:nth-child(2) {
      width: calc(100% - 5px);
      border-color: #004b93;
    }

    &:nth-child(3) {
      border-color: #004b93;
    }
    &:nth-child(4) {
      width: calc(100% - 8px);
      border-color: #004b93;
    }
  }
}
.hamburgerAction-close {
  position: absolute;
  right: 13px;
  font-size: 23px;
  display: none;
}

#app-header.darkHeader {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: 70px;

  * {
    transition: all 0.5s ease;
  }

  .logo-app {
    max-height: 50px;
    max-width: 140px;
    width: auto;
    height: auto !important;
  }
  .font-28px {
    font-size: 20px;
  }
  .font-22px {
    font-size: 18px;
  }
  .font-18px {
    font-size: 14px;
  }
  .pb-2 {
    padding-bottom: 0.3rem !important;
  }
}
#app-header.darkHeader {
  .btn-primary {
    background: $color-primary;
    color: #fff;
    border-color: $color-primary;
    &:hover {
      background: $color-secondary;
      border-color: $color-secondary;
    }
  }

  .logo-1 {
    display: none;
  }
  .logo-2 {
    display: block;
  }
  .hamburger-icon {
    span {
      border-color: $color-primary !important;
    }
  }
}

#app-header.darkHeader .logo-site {
  animation: HeaderLogo 0.7s forwards;
}
#app-header.darkHeader .py-md-4 {
  padding-top: 0.8 !important;
  padding-bottom: 0.8 !important;
}
.logo-app {
  max-height: 54px;
  max-width: 140px;
  width: auto;
  height: auto !important;
}
.rounded-50px {
  border-radius: 50px;
}
.headerMenuu {
  margin-right: 22px;margin-top: 5px;
}
.headerMenuu > li {
  padding: 10px 0px 10px;
  margin-left: 22px;
  margin-right: 22px;
  > a {
    color: $color-default;
    font-family: $font-secondary-medium;
    border-bottom: 3px solid #fff;
    padding-bottom: 5px;
    display: flex;
  }
  &:hover,
  &.active {
    a {
      color: $color-primary;
      border-color: $color-primary;
    }
  }
}

.ListingHeader {
  #app-header .topbar-n1 {
    visibility: hidden;
  }
}

.Userdropdown {
  font-size: 15px;
  &.dropdown_app_menu .dropdown_app {
    min-width: 200px;
  }
  &.dropdown_app_menu .dropdown_app ul li {
    padding-top: 11px;
    padding-bottom: 11px;
  }
  .dropdown_app_arrow{ left: 12px !important;}
}
.LoggedInLI{
  background: #F1F1F1;
  padding: 9px 15px;
margin: 0 -15px;
}


.arrow-drop-down-bg {
  width: 25px;
  height: 25px;
  background: #393939;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  flex:0 0 auto;
}

.change-branch-bg {
  background: #FFFFFF;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #E8E8E8;
  padding: 7px 8px;
  font-size: 13px;
  color: #212121;
  border-radius: 5px;
  position: relative;
  flex: 0 0 auto;

  &::before{
    content: "";
    width: 10px;
    height: 10px;
    border: 1px solid #E8E8E8;
    display: inline-block;
    position: absolute;
    top: 10px;
    left: -6px;
    margin: 0 auto;
    transform: rotate(-45deg);
    background: #fff;
    z-index: 2;
    border-width: 1px 0px 0px 1px;
  }

}

.desktop-show{
  display: block;
}

.mobile-show{
  display: none;
}

.part-left{
  width: 75%;
  flex: 0 0 auto;
}

.part-right{
  width: 25%;
  flex: 0 0 auto;
  justify-content: right;
}

.Header_lt{
  width: auto;
}






/*Responsive start*/
@include media($xxl) {
  .part-left {
    width: 70%;

    .font-24px {
      font-size: 20px;
    } 
  }
  .logo-text-names {
    padding: 0px 20px !important;
    margin: 0px 20px !important;
  }
}
@include media($xl) {
  #app-header {
    height: 90px;
  }
  .headerMenuu > li {
    padding: 0 0px;  
    margin: 0px 10px; 

    &.dropdown_app_menu .dropdown_toggle_btn.active + a {
      color: #EF626C;
      border-color: #EF626C;
    }

  }
  .footer_SocialLinks1 {
    svg {
      max-width: 38px;
      height: auto;
    }
  }


}

@include media($lg) {
  // .Header_lt {
  //   flex: 1;

  // }
  .part-left {
    width: 90%;

    .font-24px {
      font-size: 18px;
    }
  }

  .logo-text-names {
    padding: 0px 10px !important;
    margin: 0px 10px !important;
    border-right: none !important;

    .font-28px {
      font-size: 18px!important;
    }
  }



}

@include media($sm) {
  .mb-head-row-576{
    flex-wrap: wrap!important;
  }

}

@include media($mobile-landscape) {
  #app-header,
  #app-header.darkHeader {
    height: 100px;
  }

  #app-header.header1{
    max-height: 84px;
    padding-top: 15px !important;    
  }

  .logo-app,
  #app-header.darkHeader .logo-app {
    max-height: 42px;
    max-width: 78px;
  }
  .Header_lt {
  flex: 1;

    .font-28px.Header_business_name {
      font-size: 14px !important;
    }
    .font-18px.Hederbranch_name {
      font-size: 13px !important;
      a {
        font-size: 12px !important;
      }
    }
  }
  .footer_SocialLinks1 svg {
    max-width: 30px;
  }
  .Header_rt {
    text-align: center;
    flex-direction: column;
    .call_svg {
      width: 30px;
      height: 29px;
    }
    .open_closeTime {
      font-size: 10px !important;
    }
    .open_closeTime_devider {
      display: block;
      margin-bottom: 2px;
      line-height: 6px;
    }
  }
  .Header_lt .Hederbranch_name a {
    display: block;
    margin: 4px 0 0 0 !important;
  }
  .open_closeTime {
    display: none;
  }
  .ItemStatus_text {
    font-size: 12px !important;
    margin-top: 4px;
  }

  .Userdropdown {
    position: fixed !important;
    top: 0;
    right: 154px;
    text-align: left;

    a{
      svg{ max-width: 34px; height: auto;}
    }    
  }

  /**/

  .Hederbranch_name{
    .ms-3{
      margin-left: 5px!important;
    }
  } 

  .logo-text-names {
    padding: 0px 0px 0px 10px !important;
    margin: 0px 0px 0px 10px !important;    
  }

  .arrow-drop-down-bg {
    width: 20px;
    height: 20px;   
    margin-right: 10px;
  }

  .change-branch-bg {
    padding: 4px 3px;
    font-size: 12px;
    border-radius: 3px;

    &::before{
      content: "";
      width: 7px;
      height: 7px;    
      top: 8px;
      left: -5px;
    }
  }

  .desktop-show{
    display: none;
  }

  .mobile-show{
    display: block;
  } 

}


// .app-popup {
//   position: fixed;
//   top: 0;
//   height: 100%;
//   width: 100%;
//   align-items: center;
//   justify-content: center;
//   display: flex;
//   background: rgba(0, 0, 0, 0.6);
//   left: 0;
//   z-index: 9;
//   animation: fadeAnm 0.5s forwards;
// }
// .logout_modal{    background: #fff;
//   display: flex; position: relative;
//   border-radius: 5px;
//   align-items: center;
//   padding: 20px 30px;
//   justify-content: center; }

//   .logout_modal .app-popup-close {
//     position: absolute;
//     right: -10px;
//     top: -10px;
//     border: 0;
//     width: 25px;
//     border-radius: 50%;
//     background: #ef626c;
//     color: #fff;
//     font-size: 15px;
//     height: 25px;
// }

// .logout_modal .app-popup-close svg{ max-width: 12px; max-height: 12px;}

//
// 


// .app-popup {
//   position: fixed;
//   top: 0;
//   height: 100%;
//   width: 100%;
//   align-items: center;
//   justify-content: center;
//   display: flex;
//   background: rgba(0, 0, 0, 0.6);
//   left: 0;
//   z-index: 9;
//   animation: fadeAnm 0.5s forwards;
// }

.logout_modal .app-popup-footer button {
  justify-content: center;
  height: 33px;
  font-size: 14px;
}
.logout_modal h2
 {    font-size: 19px; margin-bottom: 0;}
 .logout_modal .app-popup-footer {
  padding-top: 0;
  margin-top: 0;
}
.app-popup .logout_modal .app-popup-close {
  position: absolute;
  right: -10px !important;
  top: 0 !important;
  width: 27px !important;
  height: 27px !important;
}


.app-popup .logout_modal  .app-popup-close {
  width: 41px;
  height: 41px;
  border: none;
  background: #ffffff;
  border-radius: 50%;
  width: 31px;
  height: 31px;
  border: none;
  background: #ef626c;
  border-radius: 50%;
  position: absolute;
  right: -50px;
  color: #ffff;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);
}



@include media($sm) {
  .mobile-login{
    left: inherit!important;
    right:0px!important;

    .dropdown_app_arrow {     
      right: 11px!important;
      left: inherit!important;
    }
  }

  .part-left{
    .font-24px {
      font-size: 16px;
    }
  } 

  .change-branch-bg{
    display: none!important;
  }
}

@media only screen and (max-width: 479px) {
.headerMenuu {
    margin-right: 0px;
 }

 .headerMenuu > li {
  padding: 0 0px;
  margin: 0px 10px;
  font-size: 12px;
}

}


@media only screen and (max-width: 375px) {

.part-left{
  .font-24px {
    font-size: 14px!important;
  }
} 

.Header_lt .font-28px.Header_business_name {
  font-size: 14px !important;
}
.Header_lt .font-18px.Hederbranch_name {
  font-size: 11px !important;
}
.Header_lt .font-18px.Hederbranch_name a {
  font-size: 11px !important;
}

}