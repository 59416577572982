.site-tabs {
  .nav {
    li {
      &:not(:last-child) {
        margin-left: 2px;
      }
      .nav-link {
        background: none;
        border: none;
        font-size: 16px;
        position: relative;
        color: $color-default;
        padding-bottom: 15px;
        transition: all 0.5s ease;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          width: 0px;
          height: 2px;
          margin: 0 auto;
          transition: all 0.4s ease;
          background: red;
          bottom: 0px;
        }

        &:hover,
        &:focus,
        &.active {
          color: $color-primary;
          font-family: $font-samibold;
          &::after {
            width: 100%;
          }
        }
      }
    }
  }

  &.site-tabs-2 {
    ul {
      li {
        border-radius: 3px;
        margin-right: 15px;
        .nav-link {
          border: 1px solid #dfdfdf;
          border-radius: 3px;
          padding: 0 10px;
          height: 33px;
          display: flex;
          align-items: center;
          cursor: pointer;
          font-size: 12px !important;

          &::after {
            display: none;
          }

          .arrowDown_tab {
            position: absolute;
            bottom: -9px;
            left: 0;
            right: 0;
            text-align: center;
            fill: $color-primary;
            opacity: 0;
          }
        }

        &:hover {
          .nav-link {
            font-family: $font-primary;
          }
        }
        &:hover,
        &.active {
          .nav-link {
            background-color: $color-primary;
            border-color: $color-primary;
            color: #fff;
            font-size: 12px !important;
          }
          .arrowDown_tab {
            opacity: 1;
          }
        }

        &.active {
          .nav-link {
            font-family: $font-samibold;
          }

          &:hover {
            .nav-link {
              font-family: $font-samibold;
            }
          }
        }
      }
    }
  }
}

.tabs-vertical-design2 {
  width: 220px !important;
  ul {
    li {
      a {
        border-width: 0px;
        border-radius: 0px;
        padding: 9px 16px;

        &::before {
          left: inherit;
          right: 0px;
          background-color: $color-primary;
          width: 2px;
          height: 17px;
          top: 9px;
        }
      }

      &:hover {
        a {
          color: $color-primary;
        }
      }
      &:hover {
        a {
          color: $color-primary;
        }
      }
      &.ui-tabs-active {
        a {
          color: $color-primary;
          font-family: $font-bold;
        }
      }
    }
  }
}

@include media($mobile-landscape) {
  .tabs-vertical-design2 {
    width: 100% !important;

    ul {
      display: flex;
      overflow: auto;
      padding-bottom: 5px;
      li {
        flex: 0 0 auto;

        a::before {
          top: inherit;
          bottom: 0;
          width: 100%;
          height: 2px;
          right: inherit;
          left: 0;
        }

      }
    }
  }
  .site-tabs.site-tabs-2 ul li {
    margin-right: 6px;
    &:last-child{margin-right: 0px;}
  }
}
