body {
  font-family: $font-primary;
  font-weight: normal;
  color: $color-default;
  font-size: 16px;
}

.text-primary {
  color: $color-primary !important;
}

.border-primary {
  border-color: $color-primary !important;
}
.rsc-slider-item-img {
  height: 483px;
}

.img-outer,
.rsc-slider-item-img > div {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-outer img,
.rsc-slider-item-img > div img {
  object-fit: cover;
  max-width: calc(100% + 10px);
  min-height: 100%;
  min-width: calc(100% + 10px);
  max-height: calc(100% + 10px);
  width: auto;
  height: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.BackToTop {
  width: 50px !important;
  height: 50px !important;
  bottom: 20px;
  right: 20px;
  background: $color-primary-dark !important;
  animation: uparrow 0.6s infinite alternate ease-in-out;
}
.min-w-inherit {
  min-width: inherit !important;
}

.ItemStatus_text[data-name="Open Now"] {
  color: #fff;
  background: #27A21A;  
  font-size: 13px;
  padding: 5px 8px;
  border-radius: 20px;
}
.ItemStatus_text[data-name="Closed"] {  
  color: #fff;
  background: #FF0000;  
  font-size: 13px;
  padding: 5px 8px;
  border-radius: 20px;
}

@keyframes uparrow {
  0% {
    -webkit-transform: translateY(0);
    opacity: 0.4;
  }
  100% {
    -webkit-transform: translateY(-0.4em);
    opacity: 1;
  }
}

.form-control:focus {
  border-color: #ced4da !important;
}

.DealsDetailContent {
  max-height: calc(100vh - 200px);
  overflow: auto;

  p {
    margin-bottom: 20px;
  }
}
.deals_img1 {
  width: 300px;
  height: 200px;
}
.tabOffter {
  position: relative;
  top: -1px;
  .tag_offerNum {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 15px;
    height: 100%;
    font-size: 12px;
    padding-top: 3px;
  }
}
.list-default {
  li {
    position: relative;
    padding-left: 17px;

    &::before {
      content: "";
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #393939;
      position: absolute;
      left: 0;
      top: 12px;
    }
  }
}

.categorieName_wrap {
  position: relative;
  padding-right: 99px;

  .filter-categorieName {
    overflow: auto;
    margin-right: 40px;
    padding-bottom: 8px;
  }

  .FiltersButton {
    position: absolute;
    right: 0;
    top: -3px;
  }
}

.footerLogo {
  img {
    max-width: 90px;
  }
}
.nav-wrapper-sticky {
  position: fixed;
  top: 70px;
  z-index: 3;
  width: 100%;
  border-bottom: 1px solid #dee2e6;
  background: #fff;

  .nav-wrapper-inner {
    border-bottom-width: 0px !important ;
  }
}

.open_closeTime {
  margin-left: 15px;
}
.call_svg {
  position: relative;
  top: -2px;
}

.today_open_time0 {
  margin-left: 14px;
}

.Header_business_name {
  margin-top: 6px;
}
.map-wrap-bottom {
  max-width: 520px;
}
.cal_icon {
  top: 9px;
  padding-right: 1px;
}
.date_time_ion {
  top: 10px;
}
.membership_cards_img {
  height: 250px;
}

//   .vouchar_heading {
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     max-width: 75ch;
// }

.font-13px.opacity-05 {
  opacity: 0.7 !important;
}

.statusdelver {
  &.statusdelver_overflow {
    overflow: auto;
    text-align: center;
    padding-bottom: 48px;
    ul {
      display: inline-flex;
      width: auto !important;
      margin: 0 auto;
      float: none !important;
    }
  }
}
table .dropdown .dropdown-item{padding: 9px 15px;line-height: 12px;}
.dropdown_Btn_wrap{ position: relative;}
.bg-transparent.border-0.dropdown_Btn {
	/* display: block; */
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 3;
  font-size: 28px;
  color: #7e7e7e; font-size: 0px;
  line-height: 28px;
  font-family: arial; font-size: 0px;
  padding-bottom: 14px;
}
.bullet_horz_icon {
	position: absolute;
	left: 50%;
	top: 50%;transform: translate3d(-50%, -50%, 0);
}
.bg-transparent.border-0.dropdown_Btn svg,
.bg-transparent.border-0.dropdown_Btn img {
	transition: all 0.5s ease;
	position: relative;
	z-index: -1;
}
.deliverytable th:last-child,
.Invoices_table_wrap:last-child{ text-align: center;}
.vouchar_heading_wrapp{ position: relative;
  .vouchar_fullName{
    background: #000;
    padding: 8px 15px;
    border-radius: 6px;
    font-size: 16px;
    position: absolute;
    left: 50%;
    top: -25px;
    text-align: center;
    width: 227px;
    transform: translateX(-50%); display: none;
  
    &::before{ content: "";
      content: "";
      width: 11px;
      height: 11px;
      background: #000;
      position: absolute;
      left: calc(50% - 11px);
      bottom: -5px;
      transform: rotate(45deg);
    }
  }

  &:hover{
    .vouchar_fullName{ display: block;}
  }

}

.dropdown-item.active, .dropdown-item:active {
	color: #333;
	background-color: #eee;
}
.dropdown-menu{padding-left: 8px;
  padding-right: 8px;}
.dropdown-menu li{ margin-bottom: 1px;}
.td_Status_01 {
	width: 100%;
	white-space: nowrap;
}
td .dropdown .dropdown-item:hover, .dropdown-item:focus {
	background-color: #fff;
}

.member-details-dec{
  font-size: 16px!important;
  span{
    font-size: 16px!important;
  }
}

.someoneElse_info{
  .form-select{
    &:focus{
      outline: none!important;      
      box-shadow: none!important;
    }    
  }
}

.date-row-full{
  .react-datepicker-wrapper{
    width: 100%;
    position: relative;
  } 

  .input_DateRange{
    background: url(../../img/calendar-icon.png) 96% 11px no-repeat;  
    background-size: 14px;
    cursor: pointer;
  }

  svg{
    display: none!important;
  }
}

.pagination-row {
  width: 100%;
  display: flex;
  justify-content: end;
}

.terms_container{
  ul,ol{
    padding: 0px 0px 0px 10px !important;
    margin: 0px !important;

    li{
      font-size: 14px !important;
      color: #393939 !important;
      list-style-type: disc;
      padding: 0px 0px 0px 0px !important;
      margin: 0px 0px 5px !important;

      &::before{
        display: none!important;
      }

      a{        
        color: #393939!important;
        font-size: 14px!important;
        text-decoration: none!important;

        &:hover{
          color: #EF626C!important;
        }
      }
    }
  }

  h1,h2,h3,h4,h5,h6{
    color: #393939;
  }

  p,div,span,label{
    font-family: "google_sansregular";
    font-weight: normal;
    color: #393939;
    font-size: 14px!important;
  }

  a{        
    color: #393939!important;
    font-size: 14px!important;
    text-decoration: none!important;

    &:hover{
      color: #EF626C!important;
    }
  }
}


.prdouct-table-view{
  table tr td:first-child {
    border-left: none;
  }
} 

.mobile-footer-section {
  width: 100%;
  background: #fff;
  padding: 10px;
  position: fixed;
  bottom: 0px;
  border-top: solid 1px #d5d5d5;
  z-index: 99;
  display: none!important;
}




/*Responsive Start*/
@media (min-width: 1200px) {
  #app-header.darkHeader .call_svg {
    width: 22px;
    height: 23px;
  }
  #app-header.darkHeader .call_svg_wrapp {
    margin-right: 12px !important;
  }
}

@media (max-width: 1400px) {
  #app-header.darkHeader .py-md-4 {
    padding-top: 0.8 !important;
    padding-bottom: 0.8 !important;
  }
}

@media (max-width: 1199px) {
  .deals_img1 {
    width: 300px;
    height: 170px;
  }
  #app-header {
    .logo-site {
      max-width: 124px;
    }
    .navbar-header li {
      margin: 0 0px;
    }
  }

  .btn {
    height: 36px;
    line-height: 36px;
    font-size: 13px;
    padding: 0 12px;
  }

  #scrollable-content .line-height-160 {
    line-height: 160%;
  }
  /*Header Start*/
  // .navbar-toggler {
  //   display: none;
  // }
  // .hamburger-icon {
  //   display: block;
  //   cursor: pointer;
  //   position: fixed;
  //   right: 40px;
  // }
  // #app-header > .container {
  //   padding-right: 48px;
  // }

  // #navbarSupportedContent {
  //   position: fixed;
  // }

  // #navbarSupportedContent {
  //   position: fixed;
  //   width: 290px;
  //   height: 100%;
  //   left: 0;
  //   top: 0;
  //   background: #fff !important;
  //   z-index: 8;
  //   text-align: left;
  //   padding-top: 20px;
  //   padding-left: 20px;
  //   transition: all 0.5s ease;
  //   transform: translate3d(-100%, 0, 0);

  //   &.show-sidebar {
  //     transform: translate3d(0, 0, 0);
  //   }

  //   .nav-link {
  //     display: inline-block;
  //     color: #333;
  //   }
  // }
  // .navbar-expand-sm .navbar-nav {
  //   flex-direction: column;
  // }
  // .backDrop {
  //   position: fixed;
  //   left: 0;
  //   top: 0;
  //   width: 100%;
  //   height: 100% !important;
  //   background: #000 !important;
  //   opacity: 0;
  //   z-index: 6;
  //   display: none;

  //   &.selected {
  //     opacity: 0.5;
  //     display: block;
  //   }
  // }
  // .hamburgerAction-close {
  //   display: block;
  //   background: transparent !important;
  // }
  // .hamburgerAction-close {
  //   cursor: pointer;
  // }
  /*Header End*/

  .font-46px {
    font-size: 30px;
  }

  .form-floating > .form-control,
  .form-floating > .form-select {
    height: 44px;
    line-height: 44px;
  }
  .form-floating > label {
    line-height: 12px;
  }
  .form-select {
    line-height: 34px;
  }
  .textarea-default-contact {
    height: 100px !important;
  }
  .list-deals-item h3 {
    line-height: 160%;
  }

  .today_open_time0 {
    margin-left: 11px;
  }

  // .Page_btnBar {
  //   position: fixed;
  //   bottom: 0;
  //   background: #fff;
  //   box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  //   width: 100%;
  //   padding: 10px 10px;
  //   border-top: 1px solid #eee;
  //   left: 0;
  //   text-align: center;
  //   display: flex;
  //   justify-content: center;
  //   z-index: 2;
  //   margin: 0 !important;

  //   .btn {
  //     flex: 1;
  //     text-align: center;
  //     margin: 0 5px;
  //     justify-content: center;
  //     height: 40px;
  //     font-size: 14px;
  //     max-width: 200px;
  //   }
  // }

  .app-popup-footer button {
    justify-content: center;
  }
  
}

@media only screen and (max-width: 1199px) and (min-width: 768px) {
  #navbar-middle .container {
    max-width: inherit;
    padding-left: 0;
    padding-right: 0;

    .nav-wrapper-inner {
      padding-left: 20px;
      padding-right: 20px;
      .Page_btnBar {
        .btn {
          &:last-child {
            margin-left: 10px;
          }
        }
      }
    }
  }

  .container-md,
  .container-sm,
  .container {
    max-width: inherit;
    padding-left: 20px;
    padding-right: 20px;
  }

.paginationmobile {
  width: 100%;
  float: left;
  margin: 0px 0px 20px!important;
}

.selectpages{
  float: right;
}
#navbarSupportedContent {
  width: 100%;
}

#navbar-middle .navbar-header a:not(.hamburger-icon).nav-link {
  font-size: 14px;
  padding: 0 8px 0 !important;
}

.per-pagess-show {
  width: 100%;
  justify-content: end;
  display: flex;
  margin: 0px 0px 20px!important;
}

.mobile-displays{
  display: inherit!important;
}
.per-pagess-show {
  margin: 0px!important;
  float: right; 
}

.mobile-pagination-row {
  width: 100%!important;
  justify-content: end;
  padding-top: 20px!important;
  display: inline-flex!important;
}

}

@media (max-width: 767px) {
  .font-14px,
  body {
    font-size: 14px;
  }
  .font-16px {
    font-size: 14px !important;
  }
  .font-18px {
    font-size: 14px;
  }
  .font-20px {
    font-size: 18px;
  }
  .font-22px {
    font-size: 18px;
  }
  .font-36px,
  h2 {
    font-size: 22px;
  }
  .font-24px {
    font-size: 18px;
  }
  .font-50px {
    font-size: 30px;
  }
  .font-52px {
    font-size: 32px;
  }
  .font-56px {
    font-size: 36px;
  }
  .font-62px {
    font-size: 42px;
  }
  .font-26px {
    font-size: 18px;
  }
  .font-32px {
    font-size: 24px;
  }
  .font-44px {
    font-size: 26px;
  }
  .font-30px {
    font-size: 20px;
  }

  #app-header .logo-site {
    max-width: 90px;
  }

  .line-height-190 {
    line-height: 160%;
  }

  .container {
    padding-left: 15px;
    padding-right: 15px;
    max-width: inherit;
  }

  .font-46px {
    font-size: 24px;
  }
  .hamburger-icon {
    right: 15px;
  }
  #app-header .btn {
    height: 34px;
    line-height: 33px;
    box-shadow: none !important;
    font-size: 13px;
    padding: 0 9px;
    min-width: inherit;
  }
  .BannerContent-wrap {
    margin-bottom: 0;
    margin-top: -50px;
  }

  .btn {
    height: 32px;
    line-height: 32px;
    font-size: 12px;
    padding: 0 10px;
  }
  .form-control {
    font-size: 14px;
    height: 36px;
  }
  textarea.form-control {
    height: 50px;
  }
  .app-popup-container textarea {
    min-height: 100px;
  }
  .form-floating > label {
    font-size: 14px;
  }
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-select ~ label {
    line-height: 20px;
  }

  .BackToTop {
    width: 40px !important;
    height: 40px !important;
  }

  .modal.show .modal-dialog {
    padding: 10px 10px;
  }
  .image1-col {
    display: none;
  }

  .SiteNavBar1 {
    &.navbar-header a:not(.hamburger-icon).nav-link {
      font-size: 14px;
      padding: 0 15px !important;
    }
    &.navbar-header a:not(.hamburger-icon).nav-link::after {
      height: 2px;
    }
    &.navbar-header a:not(.hamburger-icon) {
      height: 44px;
      line-height: 44px;
    }
    .navbar-nav {
      flex-direction: row;
    }
  }
  .orderBtn {
    padding: 0 8px;
    min-width: inherit;
    height: 34px;
    line-height: 34px;
    font-size: 13px;
  }
  .form-select {
    line-height: 26px;
  }

  #app-header > .container {
    padding-right: 15px;
  }
  .contact-colIcon {
    width: 20px;
  }
  .map-wrap-bottom {
    flex-direction: column;
    .font-22px {
      font-size: 16px;
    }
  }
  .list-deals-item {
    flex-direction: column;
    .font-26px {
      font-size: 16px;
    }
  }
  .deals_img1 {
    width: 100%;
    margin-bottom: 15px;
    height: 170px;
  }
  .Filters_Text {
    display: none;
  }
  .categorieName_wrap {
    padding-right: 47px;
  }

  .FacilitiesHead {
    margin-bottom: 10px;
  }
  #navbar-middle .container {
    padding-left: 0px;
    padding-right: 0px;
  }
  #MealinityWidget-container {
    width: 100% !important;
  }
  .call_svg {
    top: -1px;
  }
  .Header_business_name {
    margin-top: 1px;
  }
  .nav-wrapper-sticky {
    top: 84px;
  }

  .paginationmobile {
    width: 100%;
    float: left;
    margin: 0px 0px 20px!important;
  }
  
  .selectpages{
    float: right;
  }

  #Birth_date_edit{
    .btn {
      font-size: 14px!important;
    }
  }

   #navbarSupportedContent {
    width: 100%;
  }

  
.per-pagess-show {
  width: 100%;
  justify-content: end;
  display: flex;
  margin: 0px 0px 20px!important;
}

.mobile-displays{
  display: inherit!important;
}
.per-pagess-show {
  margin: 0px!important;
  float: right; 
}

.mobile-pagination-row {
  width: 100%!important;
  justify-content: end;
  padding-top: 20px!important;
  display: inline-flex!important;
}
  

}
@media (max-width: 576px) {
  .navbar-header .navbar-collapse {
    display: block;
  }
  #navbarSupportedContent {
    width: 100%;
  }
  .SiteNavBar1 .navbar-nav {
    justify-content: space-between;
    padding: 0 10px;
  }

  #navbarSupportedContent .navbar-nav li{
    flex: 0 0 auto;
  }

  .app-popup-600px{    
    max-width: 100%;
    .Invoices_table_wrap{
      table{
        width: 800px!important;
      }
    }
  }

  .footer_SocialLinks1 svg {    
    height: 41px;
}

}

@include media($lg) {
  .m-hide{
    display: none!important;
  }
  .mobile-footer-section{
    display: block!important;
  }
}

