$color-primary: #EF626C;
$color-primary-dark: #a10920;
$color-grey-dark: #393939;
$color-secondary: #3366cc;
$color-secondary-light: #bed9f8;
$color-default: #393939;
$color-white: #fff;
$color-success: #48C600;
$color-success-2: #93C01F;
$color-green: #33AF3B;
/****** Responsive Media Queries ******/
$xs: '479px';
$sm: '576px';
$mobile-landscape: '767px';
$md: '768px';
$lg: '992px';
$xl: '1200px';
$xxl: '1400px';
