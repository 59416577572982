.table-bordered {

  border-radius: 3px;

  td,
  th {
    padding: 14px 20px;
  }

  td {
    border-left: 1px solid #d9d9d9;
    vertical-align: middle;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 52px;
    border-top: 1px solid #d9d9d9;
  }

  th {
    font-family: $font-bold;
  }

  &.table-bordered-left-less{
    td {
       border-left: none;
    }
  }
}

.table > :not(:first-child) {
  border-top: none !important;
}

.platinum-tabless{
  tr {
    td{
      &:first-child {
        border-left: none!important;
      }
    }
  }
} 
