.app-popup-400px{
    max-width: 400px;
}
.dateheadcolor{
    color: #2D2E2F;
    line-height: 15px;    
}

.downloadbtn{
    float: right;
    background: #000;
    color: #fff;
    padding: 10px 15px;    
}

.downloadbtn {
    float: right;
    background: #EF626C;
    color: #fff;
    padding: 5px 8px;
    font-size: 12px;
    border-radius: 3px;
    text-decoration: none!important;
}
.downloadbtn:hover,
.downloadbtn:focus{
    color: #fff;
    text-decoration: none!important;
}

.downicons{
    float: left;
    margin: 5px 5px 0px 0px;
}

.paddownload{
    padding: 15px 25px!important;
}

.invoiceplans{
    width: 100%;
    float: left;
    padding: 15px 10px 0px;
}
.invoicebottom{
    width: 100%;
    padding-bottom: 20px;
    float: left;
}
.inputin{
    width: 100%;
    border: solid 1px #d5d5d5;
    padding: 10px 13px;
    font-size: 14px;
    color:#666;
    border-radius: 4px;
    float: left;
}
.paybtnwid{
    width: 100%;
}

.app-popup-500px{
    max-width: 500px;
}


@include media($mobile-landscape) {
.inputin{
    padding: 14px 13px;
}

.pay-btn-show{
    margin-top: 10px;
}

}