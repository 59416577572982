/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.nav-item {
  color:red !important
}

.nav-wrapper-sticky + #scrollable-content {
  padding-top: 100px;
}

.rsc-container {
  width: 100%;
  position: relative;
  width: 80%;
  margin: 0 auto;
}
.rsc-slider {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  transition: all 0.2s ease-in-out;
}
.rsc-slider-item {
  width: 100%;
  box-sizing: border-box;
}
.rsc-slider-item-img {
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
}

.rsc-slider-item-img img {
  max-width: 100%;
}

.rsc-slider-item_transparent {
  opacity: 0.3;
}
.rsc-navigation {
  position: absolute;
  top: 0;
  height: 100%;
  cursor: pointer;
  width: 50px;
}
.rsc-navigation_left {
  left: 0;
}
.rsc-navigation_right {
  right: 0;
  text-align: right;
}
.rsc-arrow_left,
.rsc-arrow_right {
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMzA2cHgiIGhlaWdodD0iMzA2cHgiIHZpZXdCb3g9IjAgMCAzMDYgMzA2IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzMDYgMzA2OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PGcgaWQ9ImNoZXZyb24tcmlnaHQiPjxwb2x5Z29uIHBvaW50cz0iOTQuMzUsMCA1OC42NSwzNS43IDE3NS45NSwxNTMgNTguNjUsMjcwLjMgOTQuMzUsMzA2IDI0Ny4zNSwxNTMgIi8+PC9nPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48L3N2Zz4=");
}
.rsc-arrow_left {
  -webkit-transform: rotateY(-180deg);
          transform: rotateY(-180deg);
}

.heart-icons{
  position: absolute;
  right:10px;
  top:10px;
}

.heart-icons img{
  width: 20px!important;
  height: auto!important;
  min-width: auto!important;
}

.justify-content-right{
  justify-content: right;
}
















/*Responsive start*/
@media screen and (min-width: 1280px) {
  .rsc-arrow_left,
  .rsc-arrow_right {
    background-size: 100%;
  }
}
@media screen and (max-width: 720px) {
  .rsc-navigation {
    width: 40px;
  }
  .rsc-arrow_left,
  .rsc-arrow_right {
    background-size: 70%;
  }
}
.loaderHtml {
  height: 650px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* margin: auto;
  width: 0%;
  margin-top: 25%; */
}
.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3366cc;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
  margin-bottom: 15px;
}

.loader-container {
  position: fixed; /* Position the container relative to the viewport */
  top: 0;
  left: 0;
  width: 100%; /* Take up the entire width of the viewport */
  height: 100%; /* Take up the entire height of the viewport */
  display: flex; /* Use flexbox for centering */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}


.loader {
  width: 40px;
  height: 40px;
  border: 5px solid #FFF;
  border-bottom-color: #3366cc;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: spin 1s linear infinite;
  }

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 

.shimmer span,
.shimmer p,
.shimmer img,
.shimmer a,
.shimmer svg, 
.shimmer i,
.shimmer h4,
.shimmer h2,
.shimmer button {
    color: transparent !important;
    background-color: rgba(221, 221, 221, 0.513) !important;
    border-color:transparent !important;
    display: inline-block;
    border-radius: 5px !important;
    mask: 100%;
    fill:  rgba(221, 221, 221, 0.513) !important;
    pointer-events: none;
    /* mask-repeat: no-repeat; */
    /* -webkit-mask: linear-gradient(-60deg, rgba(0, 0, 0, 0.3) 30%, rgba(0, 0, 0, 0.3) 30%, rgba(0, 0, 0, 0.3) 40%)
        right/300% 100%; */
    -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
    /* background-color: SlateGray; */
    /* mask-image: linear-gradient(grey, grey); */
    background-repeat: no-repeat;
    animation: shimmer 1.5s infinite;
    /* font-size: 50px; */
    /* max-width: 200px; */
}
.error{
  color: red;
}

.is-mobile .page-wrapper {
  padding-top :10px !important;
  padding-bottom: 0px !important;
}

@keyframes shimmer {
    100% {
        -webkit-mask-position: left;
    }
}
@media screen and (max-width: 576px) {
.mobile-show-section {
  border-top: none!important;
  padding: 0px!important;
  margin: 10px 0px!important;
}

}