.form-floating > .form-control,
.form-floating > .form-select {
  height: 50px;
  line-height: 1.25;
}
.form-floating > label {
  opacity: 1;
  line-height: 16px;
  transition: all 0.3s ease;
}
.form-floating > .form-control {
  color: #202020;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 1;
  line-height: 24px;
  transform: scale(0.85) translateY(-1.7rem) translateX(0.15rem);
  color: $color-primary;
}
.form-control,
select {
  box-shadow: none !important;
}
.form-floating > .form-select {
  padding-top: 13px;
  padding-bottom: 10px;
}
.form-floating label::after {
  content: "";
  background-color: #fff;
  position: absolute;
  margin-top: 5px;
  left: 5px;
  width: calc(100% - 10px);
  height: 8px;
  background-color: #fff;
  z-index: -1;
}
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 0.9rem;
}

.border-topnbottob-left-0 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.form-select {
  color: #202020 !important;
  line-height: 36px;  

  option {
    color: #000 !important;
    background: #fff;
    font-family: Verdana;
    font-size: 14px;
  }
  &:focus {
    border-color: #ced4da;    
  }
}
.text-black {
  color: #000 !important;
}
textarea {
  resize: none;
}

.form-control-40px-parent {
  .form-floating > .form-control {
    height: 42px;
    font-size: 14px;
  }
  .form-select,
  .input-group-text {
    height: 42px;
    font-size: 14px;
    line-height: 14px;
  }
  .form-floating > label {
    line-height: 11px;
    font-size: 14px;
  }
}

.form-control-h-56px {
  height: 56px;
}
.form-floating-56px {
  .form-control {
    height: 56px;
  }
  > label {
    line-height: 23px;
  }
}

.form-check {
  .form-check-input:checked {
    background-color: #ee1e3d;
    border-color: #ee1e3d;
    box-shadow: none !important;
  }
  .form-check-input[type="checkbox"] {
    border-radius: 0;
    margin-right: 13px;
    box-shadow: none !important;
  }
  .form-check-label {
    padding-top: 4px;
  }
}
input,
a {
  outline: none !important;
}
.form-floating-select-label {
  position: absolute;
  left: 15px;
  top: -6px;
  background-color: #fff;
  padding: 0 5px;
}
.textarea_h_172 {
  height: 172px;
}
.textarea_h_100 {
  height: 100px;
}
.textarea_h_130 {
  height: 130px;
}

.form-switch{
  .form-check-input {
    width: 40px;
    height: 20px;
    border-radius: 30px !important;
  }
  .form-check-input:checked {
    background-color: #199F00;
    border-color: #199F00;
  }
}