.btn {
  height: 40px;
  padding: 0 16px;
  min-width: 72px;
  box-shadow: none !important;
  line-height: 40px;
  display: inline-flex;
align-items: center;

  &.btn-outline-primary {
    border-color: $color-primary !important;
    color: $color-primary;
    &:hover,
    &:focus {
      background: $color-primary;
      color: #fff;
      border-color: $color-primary !important;
    }
  }


  &.btn-outline-secondary {
    border-color: $color-primary !important;
    color: $color-primary;
    &:hover,
    &:focus {
      background: $color-secondary;
      color: #fff;border-color: $color-secondary !important;
    }
  }

  &.btn-outline-dark {
    border-color: $color-default !important;
    &:hover,
    &:focus {
      svg{
         fill:#fff !important;
      }
    }
  }
}
.btn-primary {
  background: $color-primary;
  color: #fff;
  border-color: $color-primary !important;
  &:hover,
  &:focus {
    background: $color-secondary;
    color: #fff;
    border-color: $color-secondary !important;
  }
}

.btn-secondary{
  background: $color-secondary;
  color: #fff;
  border-color: $color-secondary !important;
  &:hover,
  &:focus {
    background: $color-primary;
    color: #fff;
    border-color: $color-primary !important;
  } 
}

.btn-light {
  color: $color-primary;
  &:hover {
    background: $color-secondary;
    color: #fff;
    border-color: $color-secondary;
  }
}
button,
button:focus,
.accordion-button:focus {
  box-shadow: none !important;
  outline: none !important;
}
