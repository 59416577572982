.link-primary {
  color: $color-primary !important;
  &:hover {
    color: $color-primary-dark !important;
  }
}
.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 2;
}
.z-3 {
  z-index: 3;
}
.z-4 {
  z-index: 4;
}
.font-0px {
  font-size: 0px;
}
.font-10px {
  font-size: 10px;
}
.font-11px {
  font-size: 11px;
}
.font-12px {
  font-size: 12px !important;
}
.font-13px {
  font-size: 13px;
}
.font-14px {
  font-size: 14px;
}
.font-16px {
  font-size: 16px !important;
}
.font-18px {
  font-size: 16px;
}
.font-18px-imp{
  font-size: 18px;
}
.font-20px {
  font-size: 18px;
}
.font-22px {
  font-size: 22px;
}

.font-36px {
  font-size: 36px;
}
.font-24px {
  font-size: 24px;
}
.font-50px {
  font-size: 50px;
}
.font-52px {
  font-size: 52px;
}
.font-56px {
  font-size: 56px;
}
.font-62px {
  font-size: 62px;
}
.font-26px {
  font-size: 26px;
}
.font-28px {
  font-size: 28px;
}
.font-32px {
  font-size: 32px;
}
.font-44px {
  font-size: 44px;
}
.font-40px {
  font-size: 40px;
}
.font-46px,
h2 {
  font-size: 46px;
}
.font-48px {
  font-size: 48px;
}
.font-30px {
  font-size: 30px;
}
.font-76px {
  font-size: 76px;
}
.line-height-190 {
  line-height: 190%;
}
.line-height-180 {
  line-height: 180%;
}
.line-height-170 {
  line-height: 170%;
}
.line-height-160 {
  line-height: 160%;
}
.line-height-155 {
  line-height: 155%;
}
.line-height-150 {
  line-height: 150%;
}
.line-height-140 {
  line-height: 140%;
}
.line-height-130 {
  line-height: 130%;
}
.line-height-120 {
  line-height: 120%;
}
.line-height-110 {
  line-height: 110% !important;
}
.line-height-100 {
  line-height: 100% !important;
}

.border-grey{
   border-color: #C9C9C9 !important;
}
.border-light{
  border-color: #EFEFEF !important;
}
.border-7B7B7B {
  border-color: #7b7b7b !important;
}
.border-B1B1B1 {
  border-color: #b1b1b1 !important;
}
.opacity-08 {
  opacity: 0.8;
}
.max-500px {
  max-width: 500px;
}
.max-600px {
  max-width: 600px;
}
.max-880px {
  max-width: 880px;
}
.max-w-1025px {
  max-width: 1025px;
}
.max-w-inherit{ max-width: inherit !important;}
.max-w-35px{ max-width: 35px !important;}

.text-bold {
  font-weight: bold;
}
.text-success {
  color: $color-primary !important;
}

.text-warn {
  color: #FF9900 !important;
}
.text-warn-2 {
  color: #FFBF00 !important;
}
.text-green-2 {
  color: #47C200 !important;
}
.bg-black {
  background: #000;
}
.bg-black-50 {
  background: rgba(0, 0, 0, 0.5);
}
.bg-primary {
  background: $color-primary !important;
}
.rounded-10px {
  border-radius: 10px;
}
.rounded-5px {
  border-radius: 5px;
}
.rounded-8px {
  border-radius: 8px;
}
.border-white-40 {
  border-color: rgba(255, 255, 255, 0.4) !important;
}
.border-white-50 {
  border-color: rgba(255, 255, 255, 0.5) !important;
}
.fill-primary {
  fill: $color-primary !important;
}
.fill-secondary {
  fill: $color-secondary !important;
}
.fill-default {
  fill: $color-default !important;
}
.fill-green {
  fill:  $color-primary !important;
}
.rounded-bottom-left-0 {
  border-bottom-left-radius: 0px !important;
}
.rounded-top-left-0 {
  border-top-left-radius: 0px !important;
}
.rounded-top-right-0 {
  border-bottom-right-radius: 0px !important;
}
.rounded-bottom-right-0 {
  border-bottom-right-radius: 0px !important;
}
.text-grey {
  color: #999 !important;
}
.text-grey-dark {
  color: #5E5E5E !important;
}
.opacity-1 {
  opacity: 1;
}
.opacity-09 {
  opacity: 0.9;
}
.opacity-08 {
  opacity: 0.8;
}
.opacity-07 {
  opacity: 0.7;
}
.opacity-06 {
  opacity: 0.6;
}
.opacity-05 {
  opacity: 0.5;
}
.opacity-04 {
  opacity: 0.4;
}
.opacity-03 {
  opacity: 0.3;
}
.opacity-02 {
  opacity: 0.2;
}
.opacity-01 {
  opacity: 0.1;
}

a {
  text-decoration: none;
}

.link-secondary {
  color: $color-secondary;
  &:hover {
    color: $color-primary;
    .downArrow{ border-color: $color-primary ;}
  }
}
.link-default {
  color: $color-default;
  &:hover {
    color: $color-primary;
    .fill-default {
      fill: $color-primary !important;
    }
  }
}
.font-babs-bold {
  font-family: "bebas_neuebold";
}

.text-opacity-09 {
  color: rgba(0, 0, 0, 0.9) !important;
}
.bg-light {
  background-color: #f8faff !important;
}
.bg-light-2 {
  background-color: #F3F3F3 !important;
}
.bg-success {
  background-color: $color-primary !important;
}
.bg-white{
  background-color: #fff !important;
}
.max-w-800 {
  max-width: 800px;
}

.top-1 {
  top: 1px;
}
.top-2 {
  top: 2px;
}
.top-3 {
  top: 3px;
}
.top-4 {
  top: 4px;
}
.top-5 {
  top: 5px;
}
.top-6 {
  top: 6px;
}
.top-7 {
  top: 7px;
}
.top-8 {
  top: 8px;
}
.top-9 {
  top: 9px;
}
.top-10 {
  top: 10px;
}
.top--1 {
  top: -1px;
}
.top--2 {
  top: -2px;
}
.top--3 {
  top: -3px;
}
.top--4 {
  top: -4px;
}
.top--5 {
  top: -5px;
}
.top-6 {
  top: 6px;
}
.top--7 {
  top: -7px;
}
.top--8 {
  top: -8px;
}
.top--9 {
  top: -9px;
}
.top--10 {
  top: -10px;
}
.cursor-pointer {
  cursor: pointer;
}
.text-white-09 {
  color: rgba(255, 255, 255, 0.9);
}
.shadow {
  box-shadow: 0px 3px 15px #0000001a !important;
}
.h-57px {
  height: 57px !important;
}
.h-56px {
  height: 56px !important;
}
.h-54px {
  height: 54px !important;
}
.h-53px {
  height: 53px !important;
}
.h-52px {
  height: 52px !important;
}
.h-51px {
  height: 51px !important;
}
.h-50px {
  height: 50px !important;
}
.h-30px {
  height: 30px !important;
}
.h-36px {
  height: 36px !important;
}
.h-44px {
  height: 44px !important;
}
.h-23px {
  height: 23px !important;
}
.h-24px {
  height: 24px !important;
}
.h-26px {
  height: 26px !important;
}
.text-secondary {
  color: $color-secondary !important;
}
.text-default{ color: $color-default !important;}
.bg-secondary {
  background-color: $color-secondary !important;
}
.bg-light-grey{
   background-color: #F8F8F8;
}
.bg-dark {
  background-color: $color-default !important;
}
.flex-0-0-auto {
  flex: 0 0 auto;
}
.ract-42px {
  width: 42px;
  height: 42px;
  min-width: 42px;
}
.ract-26px {
  width: 26px;
  height: 26px;
  min-width: 26px;
}
.ract-20px {
  width: 20px;
  height: 20px;
  min-width: 20px;
}
svg {
  transition: all 0.5s ease;
}
.min-w-65px {
  min-width: 65px !important;
}
.min-w-1300px{ min-width:1300px;}
.min-w-1250px{ min-width:1250px;}
.min-w-1200px{ min-width:1200px;}
.font-20px-imp{ font-size: 20px !important;}

@include media($xl) {
  .font-14px {
    font-size: 14px;
  }
  .font-16px {
    font-size: 14px !important;
  }
  .font-18px {
    font-size: 14px;
  }
  .font-20px {
    font-size: 16px;
  }
  .font-22px {
    font-size: 18px;
  }
  .font-28px {
    font-size: 22px;
  }
  .font-36px,
  h2 {
    font-size: 30px;
  }
  .font-40px {
    font-size: 30px;
  }
  .font-24px {
    font-size: 18px;
  }
  .font-50px {
    font-size: 40px;
  }
  .font-52px {
    font-size: 42px;
  }
  .font-56px {
    font-size: 46px;
  }
  .font-62px {
    font-size: 52px;
  }
  .font-26px {
    font-size: 20px;
  }
  .font-32px {
    font-size: 26px;
  }
  .font-44px {
    font-size: 34px;
  }

  .font-30px {
    font-size: 24px;
  }

  .banner-site {
    height: 600px;
  }

  .line-height-190 {
    line-height: 170%;
  }
  .line-height-180 {
    line-height: 160%;
  }
  .line-height-170 {
    line-height: 150%;
  }
  .line-height-160 {
    line-height: 140%;
  }
  .line-height-150 {
    line-height: 150%;
  }
  .ract-42px {
    width: 32px;
    height: 32px;
    min-width: 32px;
  }
  .font-18px-imp {
    font-size: 16px;
  }
}

@include media($mobile-landscape) {
  .h-57px {
    height: 46px !important;
  }
  .h-50px {
    height: 44px !important;
  }
  .line-height-130 {
    line-height: 120%;
  }
  .line-height-150 {
    line-height: 130%;
  }
  .ract-42px {
    width: 24px;
    height: 24px;
    min-width: 24px;
  }
  .font-20px-imp{ font-size: 18px !important;}
  .font-18px-imp {
    font-size: 16px;
  }
}
