.white_devider_110px {
  width: 113px;
  height: 0px;
  font-size: 0px !important;
  border: 1px solid #ffffff;
  margin: 0 auto;
}

.historybtn{
  padding: 0px 8px;
  font-size: 15px;
  margin-right: 10px;
}
.pinbtn{
  padding: 0px 8px!important;
  min-width: auto;  
  font-size: 15px;
}
.pin-popup-365{
  max-width: 365px!important;
  width: 365px;
}

.pinlist{
  width: 100%;
  float: left;

  h2{
    width: 100%;
    text-align: center;
    float: left;
    font-size: 16px;
    color: #000000CC;
  }

  ul{
    width: 100%;
    text-align: center;
    float: left;
    padding: 15px 0px;

    li{
      display: inline-block;
    }
  }
}
.pininput {
  width: 65px;
  float: left;
  border: solid 1px rgba(0, 0, 0, 0.3);
  text-align: center;
  height: 45px;
  margin: 0px 5px;
  font-size: 20px;
  padding: 5px 10px;
  color: #000;
}
.poppad {
  padding: 20px 0px 5px !important;
}
.footerbtns{
  width: 100%;
  float: left;
  padding: 0px 10px;
}
.footershow{
  width: 100%;
  float: left;
}
.canpinbtn{
  border: solid 1px #EF626C;
  background: #fff;
  float: left; color: #EF626C;
  margin-right: 15px;
}
.bottomrightbtn{
  float: right;
}
.btnshows{
  float: left;
  margin-left: 10px;;
}
.btn-padlr{
  margin:0px 5px;
}
.fonts14px{
  font-size: 14px!important;
}
.membershipimg img {
  height: 300px!important;
}
.box-h{
  height: 470px;;
}
.vouchar_heading {
  height: 46px;
  overflow: hidden;
}
.set_error {
  width: 100%;
  padding: 0px 38px 15px;
  margin-top: -5px;
  float: left;
}
.printiconss{
  float: right;
}
.printiconss ul{
  float: right;
}
.printiconss ul li{
  float: left
}
.printiconss ul li a {
  float: left;
  background: #f1f1f1;
  padding: 5px 5px;
  margin-right: 8px;
  border-radius: 3px;
  height: 27px;
display: flex;
align-items: center;
}
.printiconss ul li a:hover{
  float: left;
  background: #E5E5E5;  
}
.padlr0{
  padding: 0px;
}

.vouchar_heading h5{
	white-space: nowrap;
	width: 80%;
	overflow: hidden;
	text-overflow: ellipsis;
	margin: 0 auto;
}

.celender-row{
  .react-datepicker-wrapper{
    width: 100%!important;
  }
}
/*Responsive start*/


@include media($xxl){
  .printiconss ul li a {margin-right: 4px;}
}


@media (max-width: 479px) {
.pin-popup-365 {
  max-width: 300px !important;
  width: 300px;
}
.pininput {
  width: 45px;  
  height: 45px;
  font-size: 16px;
}
.bottomrightbtn button {
  font-size: 14px; 
  min-width: auto!important;
}

.vouchar_heading {
  height: 40px;
  overflow: hidden;
  padding: 7px 0px!important;
  margin: 0px!important;
}

}




.card_voucher {
  background: transparent linear-gradient(124deg, #c04848 0%, #480048 100%) 0%
    0% no-repeat padding-box;

  &.color1 {
    background: transparent linear-gradient(124deg, #23e5e2 0%, #6d3cb8 100%) 0%
      0% no-repeat padding-box;
  }
  &.color2 {
    background: transparent linear-gradient(124deg, #ff512f 0%, #dd2476 100%) 0%
      0% no-repeat padding-box;
  }
  &.color3 {
    background: transparent linear-gradient(124deg, #78ffd6 0%, #007991 100%) 0%
      0% no-repeat padding-box;
  }
  &.color4 {
    background: transparent linear-gradient(124deg, #8e2de2 0%, #4a00e0 100%) 0%
      0% no-repeat padding-box;
  }
  &.color5 {
    background: transparent linear-gradient(124deg, #22c1c3 0%, #fdbb2d 100%) 0%
      0% no-repeat padding-box;
  }

  &.color6 {
    background: transparent linear-gradient(131deg, #8a2387 0%, #f27121 100%) 0%
      0% no-repeat padding-box;
  }

  &.card_voucher_design_8 {
    background:#2D2E2F;
  }

  .btn {
    position: relative;
    z-index: 2;
  }
}

.card_voucher:before {
  content: "";
  border: 8px dotted #fff;
  bottom: 0;
  left: -6px;
  position: absolute;
  right: -3px;
  top: -6px;
  width: calc(100% + 11px);
  height: calc(100% + 12px);
}

@include media($lg) {
  .voucherInfo_right {
    border-width: 0px 1px 1px 1px !important;
    border-left: 1px solid #dee2e6 !important;
  }
}

/*vouchar update css*/

.printlist{
  float: right;
}

.printlist ul{
  float: left;
}

.printlist ul li{
  float: left;
}
.printlist ul li a {
  float: left;
  background: #F1F1F1;
  padding: 4px 5px 1px;
  border-radius: 3px;
  margin-right: 5px;

  img{
    width: 14px;
  }
}
.printlist ul li a:hover {
  background: #E5E5E5;
}

.app-popup-410px{   
  max-width: 410px;  
}

.qrinfodiv{
  width: 100%;
  float: left;
  padding: 10px 0px;
  text-align: center;
}
.padlr0{
  padding: 0px!important;
}

