
.delivery_mid{
    width: 450px;
    margin: 0px auto;
    display: table;
}
.delivery_mobile_page{
    width: 100%;
    float: left;
    background: #fff;
}
.header_del{
    width: 100%;
    float: left;
    text-align: center;
    padding:15px 0px;
    border-bottom: solid 1px #ECECEC;

    img {
        height: 50px;
    }
}
.delivery_content{
    width: 100%;
    float: left;
    padding: 25px 15px;
}
.deliveryinfomation{
    width: 100%;
    float: left;
    border: 1px solid #DCDCDC;
    border-radius: 5px;
    padding: 20px 20px 0px;    
}

.bookdeliveryinfo{
    width: 100%;
    float: left;
    border: 1px solid #DCDCDC;
    border-radius: 5px;    
}
.padlr{
    padding: 30px 0px 0px!important;
}
.pad_row_del{
    padding: 0px 5px 0px 15px!important;
}

.deliveryhead{
    width: 100%;
    float: left;
    padding: 20px 10px 40px;
    border-bottom: solid 1px #DCDCDC;
}

.statusdelver{
    width: 100%;
    float: left;
    ul{
        width: 100%;
        float: left;
        text-align: center;
       
    }
    
    li {        
        position: relative;
        padding: 0px 15px;
        display: inline-block; 
        font-size: 14px;
        width: auto;
        font-family: "google_sansmedium";               
    
        &::before {
            content: "";
            position: absolute;
            left: 48%;
            bottom: -24px;
            width: 10px;
            height: 10px;
            background: #2CC400;
            border-radius: 50%;
        }

        &::after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: -20px;
            border-bottom: solid 2px #2cc400;
            z-index: 1;
            width: calc(100% + 6px);
        }

        &:nth-last-child(2){
            &::after   {
                width: calc(100% - 3px);
            }
        }
        
      }
    
}

.statusdetails{
    width: 100%;
    float: left;
    padding: 20px 10px 0px;
}

.date_status{
    width: 100%;
    float: left;
    padding: 10px;

    li{
        width: 100%;
        float: left;
        margin-top: -19px;
        margin-bottom: 15px;             
    }
    
}

.del_box_status{
    width: 100%;
    float: left;
    background: #F3F3F3;
    border-radius: 3px;
    padding: 10px 15px;
}

.padr{
    padding-right: 0px;
}

.datebox{
    width: 100%;
    float: left;
    position: relative;
    height: 100%;

    ul{
        float: left;
        position: relative;
        height: 100%;

        li{
            width: 100%;
            float: left;
            position: relative;  
            height: 100%;      
            
            &::before {
                content: "";
                position: absolute;
                right: -15px;                
                background: url(../../img/border-arrow.jpg) left top no-repeat;
                width: 1px;                
                height: 100%;
                z-index: 99999!important;
                background-position: bottom!important;
                bottom: 0px!important;
            }
            &::after {
                content: "";
                position: absolute;
                right: -15px;
                top: 8px;                
                height: 100%;
                border-right:solid 1px #2D2E2F;
                z-index: 1;
            }  
        }
    }

    

}


.datelists{
    width: 100%;
    float: left;    
    position: relative;
    height: 100%;
    
    &::before {
        content: "";
        position: absolute;
        right: -20px;
        top: -1px;
        width: 10px;
        height: 10px;
        background: #2D2E2F;
        border-radius: 50%;
        z-index: 99;
    }  
   
}

.datelists span {
    width: 60px !important;
    float: left;
    line-height: 20px;
    font-size: 14px;
    margin-top: -5px;
}

.delcover{
    background: #FFF9EF!important;
}


.statusdetails > div:last-child li::after {
    display: none;
}

.statusdetails > div:last-child li::before {
    display: none;
}

.statusdetails > div:last-child li {
    margin-bottom: 0px;
}

.statusdelver li:last-child::after {
    display: none;
}

.pb-3 {
    padding-bottom: 1rem !important;
}
.text-uppercase {
    text-transform: uppercase !important;
}
.mb-1 {
    margin-bottom: 0.25rem !important;
}
.font-primary-semibold {
    font-family: "google_sansmedium";
}

.viewdetailsbtns {
    width: 100%;
    float: left;
    margin: -10px 0px -15px;
    z-index: 99999;
    position: relative;
}
.viewinfobtn {
    width: 100%;
    background: #fff;
    color: #3366CC;
    font-size: 14px;
    padding: 5px 10px;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.1607843137);
    border-radius: 0px 0px 3px 3px;
    float: left;
    height: 35px;
    line-height: 23px;
    font-weight: 500;
    text-align: center;   
}


.del_less{
    display: none;
}


@media (max-width: 600px) {
    .statusdelver li::after {
        content: "";
        width: 113%;
        }
    
    .statusdelver li {
        padding: 0px 5px;
        font-size: 13px;
    }
    .datelists span {
        font-size: 12px;
        width: 50px !important;    
    }
    .statusdelver.statusdelver_overflow {
        padding-bottom: 40px;
    }

    .m-p-top{
        margin-top: 10px!important;
    }
    
}
    
    
    
@media (max-width: 375px) {
    .statusdelver li {
        padding: 0px 4px;
        font-size: 11px;
    }
    .datebox ul li::before {
        bottom: -46%;
    }

    .delivery_mid{
        width: 100%;
        margin: 0px auto;
        display: table;
    }

    .datelists span {
        font-size: 10px;
        width: 43px !important;
        line-height: 15px;
    }
    
}




