.headinfo{
    padding-left: 10px;
    float: left;
}
.investtxt{
    float: left;
    border-right: solid 1px #D4D4D4;
    padding-right: 30px;

    p{
        color: #606060;            
    }
} 
.touchtxt{  
    label{
        color: #2D2E2F;
        font-size: 16px;        
    }
} 

.backs{
    float: left;
    margin-top: 7px;;
}

.inveimg{
    float: left;
}

.dashboardrow{
    width: 100%;
    padding: 15px 0px 40px;
    float: left;
}

.pointshow{
    background: #E9E9E9;
    color: #575757;
    border-radius: 50%;
    width: 27px;
    height: 27px;
    float: left;
    text-align: center;
    padding-top: 6px;    
}

.businesslist {
    float: left;
    width: 85%;
    border: 1px solid #c1c1c1;
    padding: 10px 10px;
    border-radius: 4px;
    position: relative;
    background: url(../../img/down-arrows.png) 94% 16px no-repeat;    
    -webkit-appearance: none;
    background-size: 14px;
    outline: none;
    font-size: 14px;
    color: #5E5E5E;
    height: 44px;;
}

.listarrow{
    position: relative;
}

.overviewstrip{
    background: #F8F8F8 0% 0% no-repeat padding-box;
    border: 1px solid #E2E2E2;
    border-radius: 5px;
    width: 100%;
    float: left;

    h3{
        width: 100%;
        display: block;
        padding: 20px 25px;
        border-bottom: solid 1px #E2E2E2;
        font-family: "google_sansmedium";
    }
}

.dashsection{
    width: 100%;
    float: left;
    padding: 35px 25px 10px;
}

.chartview{
    width: 100%;
    float: left;

    img{
        width: 100%;
    }
}

.chartlist{
    width: 85%;
    float: right;

    li{
        width: 100%;
        float: left;        
        position: relative;
        margin-bottom: 20px;
    }
}

.totalbg{
    width: 100%;
    float: left;
    background: #fff;
    border-radius: 13px;
    padding: 20px 20px 20px 40px;    
    border: solid 1px #E2E2E2;
    
}

.totaltop{    
    width: 100%;
    float: left;
    padding: 0px 15px;
    position: relative;

    h5{
        color: #222;
        font-family: "google_sansregular";
    }  
}


.chartlist li:nth-child(1) > div{

    .totaltop{

        &::before{
            content: "";
            position: absolute;
            width: 4px;
            height: 94%;
            background: #3366CC;
            top: 1px;
            left: -5px;
            border-radius: 4px;
        } 
        h2{
            color: #3366CC;
        }
    }   

}

.chartlist li:nth-child(2) > div{

    .totaltop{

        &::before{
            content: "";
            position: absolute;
            width: 4px;
            height: 94%;
            background: #EF626C;
            top: 1px;
            left: -5px;
            border-radius: 4px;
        } 

        h2{
            color: #EF626C;
        }
    }   

}

.chartlist li:nth-child(3) > div{

    .totaltop{

        &::before{
            content: "";
            position: absolute;
            width: 4px;
            height: 94%;
            background: #33AF3B;
            top: 1px;
            left: -5px;
            border-radius: 4px;
        } 

        h2{
            color: #33AF3B;
        }
    }   

}

.detailsbtn{    
    border: 1px solid #EF626C;
    border-radius: 4px;
    color: #EF626C;
    padding: 8px 10px;
    text-transform: capitalize;
    margin: 10px 0px 0px 17px;
    line-height: 13px;
    transition: .2s;
    float: left;


    &:focus, &:hover{
        background: #EF626C;
        color: #fff;
    }
}

.pointsviewbox {
    width: 100%;
    float: left;
    border: solid 1px #E2E2E2;
    border-radius: 5px;
}

.dashviewpoints{
    width: 100%;    
    float: left;
    margin-bottom:25px;

    h2{ 
        background: #F8F8F8;
        padding: 10px 25px;
        border-bottom: solid 1px #E2E2E2;
        border-radius: 5px 5px 0px 0px;
        float: left;       
        width: 100%; 

        span{
            font-family: "google_sansbold";
            color: #3366CC;
            float: left;
            padding-top: 5px;
            font-size: 18px;
            line-height: 22px;
        }
    }
}

.detailsbtn1 {
    border: 1px solid #EF626C;
    border-radius: 4px;
    color: #EF626C;
    padding: 8px 10px;
    text-transform: capitalize;
    margin: 0px 0px 0px 0px;
    transition: 0.2s;
    line-height: 13px;
    float: right;
    font-family: "google_sansregular";

    &:focus, &:hover{
        background: #EF626C;
        color: #fff;
    }
}

.totalviewinfo{
    width: 100%;
    float: left;
    padding: 35px 30px;

    span{
        font-size: 14px;
        font-family: "google_sansbold";
        color: #646464;
    }

    h5{
        font-size: 20px;
    }
}





@media (max-width: 1024px) {

    
.dashviewpoints {
    h2{        
        padding: 10px 15px;
    }   
}

.totalbg {
    padding: 15px 15px 15px 25px; 
    border-radius: 8px;   
}

.totalviewinfo {
    padding: 20px 15px;
}

.totalviewinfo {
    h5{
        font-size: 16px;
    }    
}

}


@media (max-width: 992px) {

.tabrights{
    float: right;
}

.chartlist {
    width: 100%;
    float: left;
    margin: 25px 0px 0px;
}

.chartlist li {
    width: 30%;
    float: left;
    position: relative;
    margin: 10px 10px;
}

}

@media (max-width: 767px) {
.tabrights {
    li{
        margin-right: 10px;
        margin-left: 0px!important;
    }    
}
.chartlist{
    li{
        width: 48%;        
        margin: 5px 5px;
    }    
}

.totalbg {
    padding: 20px 20px 20px 25px;
    min-height: auto;
}

.businesslist {
    width: 100%;    
    background-position: 96% 16px;   
}

.totalviewinfo {
    .mb-2{
        margin: 0px 0px 5px!important;
    }   
}
.totalviewinfo {
    h5{
        margin-bottom: 10px!important;
    }    
}
.totalviewinfo {
    text-align: center;
    h5{
        font-size: 18px;
    }    
}
.investtxt {
    border-right: none;
}

.touchtxt { 
    margin: 5px 0px 0px 0px;
}

.m-bottom0{
    padding-bottom: 0px!important;
}

}

@media (max-width: 575px) {
.chartlist{
    li{
        width: 100%;        
        margin: 5px 0px;
    }    
}

.tabrights {
    float: left;
}

}



















